
.root {
    > svg {
        > circle {
            transition: all 300ms ease-in-out;
        }
    }
}

.tableDataOuter {
    max-height: 850px;
    flex-basis: 75%;

    @media only screen and (max-width: 1200px) {
        flex-basis: 100%;
        min-width: 100%;
    }
}

.scrollbar {
    background-color: #474746;
    padding: 0;

    &:hover {
        background-color: #2B2E2F;
        .thumb {
            background-color:#A2A1A7;
        }
    }
  }
[class].thumb {
    background-color: var(--scrollColor);
    border-radius: 8;
    &:hover {
        background-color:#A2A1A7;
    }
}