@import '~shared/styles/config';

.slider {
    // margin-top: 350px;
    margin: 40px 0;

    :global {
        .slick-slider {
            position: static;
            margin-top: 0;
        }

        .slick-slide div {
            height: 100%;
        }

        .slick-arrow {
            top: 150px; // Half of image height
            transform: translateY(-30px); // Half of arrow height
        }
    }

    .item {
        width: 620px;

        .imgContain {
            height: 300px;
            max-height: 300px;

            img {
                object-fit: cover;
                height: 100%;
                width: 100%;
            }
        }

        .content {
            padding: 20px;

            h2 {
                margin-bottom: 5px;
                font-size: 36px;
            }

            // padding: 0 25px 25px;

            p {
                font-size: 20px;
                font-weight: 300;
            }
        }
    }
}

@include viewport(mobile) {
    .slider {
        margin: 60px 0;

        .item .content {
            padding: 30px;
        }
    }
}

@include viewport(tablet) {
    .slider {
        margin: 120px 0;
    }
}