@import '~shared/styles/config';

.careers {
    --www-careers-blur: 0;
    --www-careers-darken: 0;

    padding: 0;
    margin: 0;
    position: relative;
    overflow: hidden;
    background: #fff;

    .inner {
        position: relative;
        background-size: cover;
        display: flex;

        .bg {
            // performance
            backface-visibility: hidden;
            transform: translate3d(0, 0, 0);
            transform: translateZ(0);
            content: '';
            position: absolute;
            top: -1px;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: var(--www-careers-bg-sm);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center bottom;
            clip-path: polygon(0 0, 100% 0, 100% 90%, 0 99%);
            transition: 0.2s ease-in-out;
            background-color: var(--www-careers-darken);
            background-blend-mode: overlay;
            overflow: hidden;
            // will-change: transform;

            @-moz-document url-prefix() {
                background-color: rgba(#101010, 0.6);
            }

            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                pointer-events: none;
                clip-path: inherit;
                backdrop-filter: var(--www-careers-blur, 0);
            }

            @include viewport(mobile) {
                background-image: var(--www-careers-bg);

                // blur effect
                &::after {
                    backdrop-filter: var(10px);
                }
            }

            @include viewport(tablet) {
                clip-path: polygon(0 0, 100% 0, 100% 88%, 0 95%);
            }

            @include viewport(desktop) {
                clip-path: polygon(0 0, 100% 0, 100% 70%, 0 95%);
            }

            @include viewport(xlargeDesktopUp) {
                clip-path: polygon(0 0, 100% 0, 100% 40%, 0 95%);
            }
        }

        .content {
            margin: 40px 0;
            display: grid;
        }

        .intro {
            @include viewport(desktop) {
                width: 500px;
            }
        }

        .careerCharacter {
            display: none; // to be hidden on mobile for now
            position: relative;
            height: 500px;
            // height: 683px;
            // height: 770px;
            // width: 692px;

            @include viewport(desktop) {
                // height: 770px;
                height: 683px;
            }

            .line {
                position: absolute;
                bottom: 2px;
                left: 100px;
                width: 450px;
                height: 100%;
                border-bottom: 1px solid #b0b0b0;
                z-index: 1;
            }

            .imgPos {
                top: -52px; // just tweaks, not final
                position: relative;
            }

            video {
                margin-bottom: -5px;
                height: inherit;
                width: 100%;

                @include viewport(desktop) {
                    width: auto;
                    // height: auto;
                    height: 680px;
                }
            }

            @include viewport(mobile) {
                display: flex;
                align-items: flex-end;
            }
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }
            20% {
                opacity: 0;
            }
            60% {
                opacity: 1;
            }
            100% {
                opacity: 1;
            }
        }

        .fadeIn {
            opacity: 0;
            animation: fadeIn 0.75s ease forwards;
        }
    }

    .title {
        margin: 0;
        margin-left: -4px;
    }

    .sub {
        margin: 10px 0 0;
        font-weight: $font-medium;
    }

    .selectionWrapper {
        position: relative;
        margin-top: 20px;

        @include viewport(mobile) {
            margin-top: 0;
            padding-bottom: 20px;
        }
    }

    .careersSelection {
        margin-bottom: 40px;
        color: #1a1a1a;

        .target,
        > div {
            width: 100%;
            top: -5px;
        }

        h4 {
            margin: 0;
        }

        .details {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
        }

        .detail {
            transition: 0.2s ease-in-out;

            &.item-1 {
                transform: translateX(0);
            }

            &.item-2 {
                transform: translateX(100%);
            }

            &.item-3 {
                transform: translateX(200%);
            }

            &.item-4 {
                transform: translateX(300%);
            }
        }

        .list {
            position: relative;
            display: grid;
            grid-template-columns: 1fr;
            color: #1a1a1a;
            grid-gap: 30px;

            > a:nth-child(5) {
                display: none;
            }

            @include viewport(mobile) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 20px;

                > a:nth-child(5) {
                    grid-column: 1 / -1;
                }

                &:hover > a:not(:hover):not(:nth-child(5)) {
                    h3,
                    svg {
                        opacity: 0.4;
                    }
                }
            }

            @include viewport(desktop) {
                grid-template-columns: repeat(5, 1fr);
                background: #f1f1f1;
                grid-gap: 0;

                > a:nth-child(5) {
                    display: block;
                    grid-column: auto;
                }
            }

            .item {
                display: flex;
                align-items: center;
                text-align: center;
                flex-direction: column;
                justify-content: flex-start;

                .icon {
                    width: 100px;
                    height: 100px;

                    @include viewport(tablet) {
                        width: 200px;
                        height: 200px;
                    }

                    margin: 0 20px;
                    border-radius: 50%;
                    background-color: #ededed;
                    transition: all 0.3s ease;
                    display: flex;
                    justify-content: center;
                    cursor: pointer;

                    svg {
                        width: 72px;
                        height: auto;

                        @include viewport(tablet) {
                            width: 92px;
                            height: auto;
                        }
                    }
                }

                // Item hover changes the icon color
                &:hover {
                    .icon {
                        background-color: #ba1f7e; // '#ba1f7e'
                        transform: scale(1.05);
                    }
                }

                h3 {
                    margin: 25px 0 10px;
                    text-transform: uppercase;
                }

                p {
                    margin: 0;
                    font-weight: 400;
                    font-size: 16px;
                    max-width: 285px;
                }
            }
        }

        .visit {
            margin-top: 30px;
            color: $www-academy;
            text-transform: uppercase;
            text-align: center;
            font-size: 18px;

            @include viewport(desktop) {
                display: none;
            }
        }
    }

    @include viewport(mobile) {
        .inner .content {
            margin: 60px 0 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 30px;
            justify-items: center;
        }

        .sub {
            font-weight: $font-light;
        }
    }

    &.isFrontlines .bg {
        @include viewport(xlargeDesktopUp) {
            clip-path: polygon(0 0, 100% 0, 100% 70%, 0 95%);
        }
    }

    @include viewport(desktop) {
        .inner .content {
            margin: 80px 0 0;
            grid-gap: 60px;
            align-items: flex-start;
            grid-template-columns: 1fr 3fr;
        }
    }

    @include viewport(xlargeDesktopUp) {
        .inner .content {
            margin: 150px 0 0;
        }
    }
}
