@import '~shared/styles/config';

:root {
    --partnership-accent-color: #cd923b;
}

.paperform {
    iframe {
        clip-path: polygon(
            0 0,
            45px 0,
            49px 5px,
            104px 5px,
            108px 0,
            100% 0,
            100% 100%,
            108px 100%,
            104px calc(100% - 5px),
            49px calc(100% - 5px),
            45px 100%,
            0 100%
        );
    }
}

.submitForm {
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    padding-bottom: 80px;

    @include viewport(mobile) {
        grid-template-columns: 630px;
        margin: 0px 0px;
    }

    text-transform: uppercase;

    .formContainer {
        padding: 10px 40px 20px 40px;

        form {
            color: #c0c0c0;
        }
    }
}

.success {
    display: grid;
    justify-content: center;

    svg {
        font-size: 64px;
        color: green;
        align-items: inherit;
        justify-items: normal;
        margin: 20px auto 10px auto;
    }
}

.fail {
    display: grid;
    justify-content: center;

    svg {
        font-size: 64px;
        color: #d81f27;
        align-items: inherit;
        justify-items: normal;
        margin: 20px auto 10px auto;
    }
}

@include viewport(tablet) {
    .heroBG {
        min-height: 1100px;
    }
}

.heroContainer {
    padding-top: 240px;
    padding-bottom: 50px;
    text-align: right;

    h2 {
        color: var(--partnership-accent-color);
    }
}
