@import '~shared/styles/config';

.social {
    display: flex;

    div {
        margin-left: 20px;

        &:first-of-type {
            margin-left: 0;
        }
    }
}
