@import '~shared/styles/config';

.hero {
    position: relative;
    display: flex;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding: min(30vw, 550px) 0 200px;
    // height: 70vh;
    // min-height: 780px;
    text-align: center;


    img {
        margin: 0 auto;
    }

    .earlyBird {
        text-transform: uppercase;
    }

    .cta {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 30px;

        a {
            background: $brand-fanfest-orange;
        }
    }

    @include viewport(desktopMax) {
        align-items: flex-start;
        min-height: auto;
        height: auto;
        padding: 30vw 0 100px;
    }

    @include viewport(tabletmax) {
        padding: 30vw 0 0;
    }

    .heroSvgIcon {
        margin: 0 auto;

        @include viewport(mobileMax) {
            width: 165px;
        }
    }

    .text {
        margin-top: 35px;
        color: white;

        @include viewport(mobileMax) {
            color: white;


            h2 {
                font-size: large;
            }
        }

        h1 {
            font-weight: 600;
            @include viewport(mobile) {
                text-shadow: none;
            }
        }

        h2 {
            @include viewport(mobile) {
                text-shadow: none;
            }
        }

        h3 {
            text-shadow: none;
            padding-top: 15px;
            margin: 0;
            @include viewport(mobile) {
                text-shadow: none;
            }
        }

        h2 span,
        h2 strong {
            font-weight: inherit;
            color: $brand-fanfest-orange;
        }

        p {
            width: 100%;
            max-width: 775px;
            letter-spacing: 0.05em;
            margin: 0 auto;
            margin-top: 40px;
            margin-bottom: 50px;
            font-size: 24px;
            font-weight: $font-light;
        }
    }
}
