@import '~shared/styles/config';

.error {
    text-align: center;
    padding: 5em 0 3em;

    .img {
        margin: 0 auto;
    }

    .content {
        max-width: 850px;
        margin: 0 auto;
    }

    @include viewport(mobile) {
        padding: 7em 0 5em;
    }

    @include viewport(desktop) {
        padding: 10em 0;
    }
}
