@import '~shared/styles/config';

.content {
    color: inherit;
    margin-bottom: 10vw; //200px for 1920width

    .map {
        margin: 4vw 8vw;
        position: relative;
        overflow: hidden;
        padding-top: 37.5%;

        @media only screen and (max-width: $max-1280) {
            margin: 15px;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }

    .details {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        text-align: left;
        padding-top: 80px;

        @media only screen and (max-width: '1080px') {
            grid-template-columns: 1fr;
        }

        .content {
            padding-top: 20px;
        }

        .left {
            padding-right: 10px;
            padding-top: 50px;
        }

        .right {
            margin: -100px;
            margin-top: -250px;

            @media only screen and (max-width: $max-1280) {
                margin: 0 auto;
            }
        }
    }
}
