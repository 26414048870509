@import '~shared/styles/config';

/* The container */
.container {
    display: block;
    position: relative;
    margin-bottom: 8px;

    span {
        font-size: 16px;
        text-transform: uppercase;
        @include font-regular();
    }

    .label {
        cursor: pointer;
    }

    .checkmark,
    input {
        width: 20px;
        height: 20px;
    }

    .checkmark {
        position: absolute;
        top: 1px;
        left: 0;
        // background-color: #eee;
        border: 1px solid var(--sf-terms-checkbox-color);
        border-radius: 2px;
        display: grid;
        justify-content: center;
        align-content: center;
        // transition: all 0.2s;

        svg {
            opacity: 0;
            transition: all 0.2s;
        }
    }

    input {
        position: absolute;
        top: 1px;
        left: 0;
        z-index: 1;
        opacity: 0;
        margin: 0;
        cursor: pointer;

        &:checked {
            ~ .checkmark {
                background-color: #2196f3;
                border: 1px solid #2196f3;

                svg {
                    opacity: 1;
                }
            }

            &::after {
                display: block;
            }
        }
    }
}

.container input:checked ~ .checkmark::after {
    display: block;
}
