// Helper to convert straight number to percentage
@function convertify($number) {
    @if type-of($number) == 'number' and unitless($number) {
        @return percentage(calc($number / $grid-column-count));
    }

    @return $number;
}

// Rows
@mixin row($direction: ltr, $align: stretch, $justify: flex-start, $grid-gutter: $gutter, $wrap: wrap) {
    display: flex;
    flex-wrap: $wrap;
    align-items: $align;
    justify-content: $justify;
    margin-left: -(calc($grid-gutter/2));
    margin-right: -(calc($grid-gutter/2));

    @if $direction == ltr {
        flex-direction: row;
    }

    @else {
        flex-direction: row-reverse;
    }
}

// Columns
@mixin col($width: 100%, $align: stretch, $grid-gutter: $gutter) {
    flex: none;
    align-self: $align;
    padding-left: calc($grid-gutter/2);
    padding-right: calc($grid-gutter/2);
    width: convertify($width);
}

// Offsets
@mixin offset-left($offset: 1) {
    margin-left: convertify($offset);
}

@mixin offset-right($offset: 1) {
    margin-right: convertify($offset);
}
