@import '~shared/styles/config';

.ul,
.ol {
    & > li {
        margin-top: 5px;
    }

    p {
        margin: 0;
    }
}

.ul {
    list-style-type: square;

    li::marker {
        font-size: 22px;
        color: $www-primary;
    }
}
