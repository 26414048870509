@import '~shared/styles/config';

.hero {
    display: flex;
    align-items: center;
    padding: 80px 0 30px;
    min-height: 280px;
    position: relative;
    background-color: rgba(#000, 0.1);
    background-blend-mode: overlay;

    h1,
    h2 {
        line-height: 1.2;
    }

    h2 {
        margin-top: 0;
        margin-bottom: 30px;
    }

    &::before {
        content: '';
        position: absolute;
        bottom: -3px;
        width: 100%;
        height: 197px;
        background-image: url(//web.ccpgamescdn.com/aws/eveonline/images/transparency-transition.png);
        background-repeat: repeat-x;
        pointer-events: none;
    }

    .content {
        flex: 1;
    }

    .buttonContainer {
        display: grid;
        grid-gap: 20px;
    }

    .btn {
        width: 100%;
    }

    @include viewport(mobile) {
        min-height: 350px;

        .content {
            margin-top: 20px;
        }

        .buttonContainer {
            grid-template-columns: max-content max-content;
        }
    }

    @include viewport(tablet) {
        padding: 100px 0;
        min-height: 450px;
    }

    @include viewport(desktop) {
        min-height: 500px;
    }
}
