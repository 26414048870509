
@import '~shared/styles/config';

.verification {
    padding-top: 20px;

    .text {
        text-align: center;
    }

    .form {
        padding-top: 30px;
    }

    .label {
        display: block;
        margin-bottom: 5px;
        color: $www-silver-gray;
    }

    .submit {
        margin-top: 40px;
    }

    .btn {
        width: 100%;
    }
}