@import '~shared/styles/config';

.richtext {
    padding: 30px 0;

    @include viewport(tablet) {
        padding: 30px 60px 30px 0;
    }

    /*************************
    * Spacing experiment
    *************************/
    > * + * {
        margin-top: 1.5rem;
        margin-left: auto;
        margin-right: auto;
    }

    h2 + * {
        margin-top: 1rem;
    }

    > * + h2 {
        margin-top: 3rem;
    }

    // img + * {
    //     margin-top: 3rem;
    // }

    // > * + h3 {
    //     margin-top: 3.5rem;
    // }

    // > * + img {
    //     margin-top: 3rem;
    // }

    // > img + img {
    //     margin-top: 1rem;
    // }

    /*************************
    * Spacing experiment ends
    *************************/
}
