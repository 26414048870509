.playButton {
    .bg {
        transition: all 0.2s ease-in-out;
    }

    &:hover {
        .bg {
            fill-opacity: 1;
        }
    }
}
