@import '~shared/styles/config';

$fp-color: #ffc038;

.language {
    color: #fff;
    overflow: hidden;
    display: inline-flex;
    font-size: 18px;
    letter-spacing: 0.01em;
    z-index: 24;
    position: relative;
    margin-bottom: 2px;
    align-items: center;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: -7px;
        height: 1;
        width: 0%;
        border-top: 1px solid $www-primary;
        display: block;
    }

    &:hover {
        &::before {
            transition: all ease-in-out 0.15s;
            width: 100%;
        }
    }

    &.fpTheme {
        &::before {
            border-top: 1px solid $www-primary;
        }
    }

    .selected {
        display: none;
        margin-left: 10px;
        text-transform: capitalize;
        // @include font-semibold();

        &:focus {
            outline: none;
        }
    }

    .globe {
        transition: 0.2s ease-in-out;
        fill: $www-primary;
    }

    ul {
        display: inline-block;
        transition: 0.2s ease-in-out;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-left: 10px;

            &.active a,
            a:hover {
                color: $www-primary;
            }

            &.active {
                // border: 1px solid green;

                &.fpTheme a {
                    color: '#5ccbcb';
                }
            }
        }

        a {
            color: #fff;
            transition: 0.2s ease-in-out;
        }

        .lang_text {
            display: none;
        }

        .lang_code {
            display: block;
            text-transform: uppercase;
        }

        .fpTheme {
            a:hover {
                color: #5ccbcb;
            }
        }
    }

    &:hover,
    &:focus {
        .selected .icon {
            color: $www-primary;
        }

        ul {
            opacity: 1;
            visibility: visible;
            transform: translateY(0);

            li:hover {
                color: white;
            }
        }

        .globe {
            filter: brightness(120%);
        }
    }

    // Color Theme
    &.colorTheme {
        ul .active a,
        ul a:hover {
            color: $www-theme-highlight !important;
        }

        svg path {
            fill: $www-theme-highlight;
        }
    }
}

@media only screen and (min-width: $min-1280) {
    .language {
        width: initial;
        position: relative;
        overflow: visible;

        .selected {
            display: block;
        }

        ul {
            position: absolute;
            display: block;
            transform: translateY(10px);
            opacity: 0;
            visibility: hidden;
            background: #212121; // TODO: Change background when moved to Navigation
            padding: 15px 20px;
            left: auto;
            right: 0;
            width: 118px;
            top: 40px;

            // &::before {
            //     content: '';
            //     position: absolute;
            //     top: -5px;
            //     right: 30px;
            //     width: 0;
            //     height: 0;
            //     border-left: 5px solid transparent;
            //     border-right: 5px solid transparent;
            //     border-bottom: 5px solid $www-primary;
            // }

            // Prevent opacity flicker on hover
            &::after {
                content: '';
                position: absolute;
                top: -20px;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: -1;
            }

            li {
                display: block;
                margin-top: 15px;
                margin-left: 0;

                &:nth-child(1) {
                    margin-top: 0;
                }
            }

            .lang_text {
                display: block;
            }

            .lang_code {
                display: none;
            }
        }

        // Color theme
        &.colorTheme {
            ul::before {
                border-bottom-color: $www-theme-highlight;
            }
        }
    }
}
