@import '~shared/styles/config';

.bg {
    // animation: FadeInOut 0.5s ease-in-out;
    // animation: FadeInOut 0.1s ease-in 0.5s;
    // transition: all 1s;
    transition: background-image 0.5s linear;
    background-position: center top;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        bottom: -3px;
        width: 100%;
        height: 100px;
        background-image: url(//web.ccpgamescdn.com/aws/eveonline/images/transparency-transition.png);
        background-repeat: repeat-x;
        pointer-events: none;

        @include viewport(mobile) {
            height: 197px;
        }
    }
}

.careerSignup {
    padding-bottom: 50px;
    padding-top: 110px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 50px;
    align-items: end;
    justify-items: center;

    @include viewport(tablet) {
        padding-top: 140px;
        gap: 80px;
        grid-template-columns: 1fr 1fr;
        justify-items: end;
        padding-bottom: 100px;
    }

    .signupForm {
        background: rgba(16, 16, 16, 0.9);
        padding: 20px;

        @include viewport(mobile) {
            padding: 40px;
        }

        a {
            color: $www-academy;
        }

        // label span svg {
        //     background: $www-academy;
        //     border: 1px solid $www-academy;
        // }

        input[type='checkbox'] {
            &:checked {
                ~ span {
                    background-color: $www-academy;
                    border: 1px solid $www-academy;
                }
            }
        }

        span[role='button'],
        div[role='button'] svg {
            color: $www-academy;
        }

        h3 {
            margin: 5px 0;
        }

        button {
            background: linear-gradient(
                180deg,
                $www-academy 0%,
                $www-academy 100%
            );
            color: #fff;

            &::before {
                background: linear-gradient(
                    180deg,
                    $www-academy 0%,
                    $www-academy 100%
                );
            }

            &:hover {
                background: linear-gradient(
                    180deg,
                    $www-academy 0%,
                    $www-academy 100%
                );
            }

            span {
                color: #fff;
            }
        }

        @include viewport(mobileMax) {
            button {
                font-size: 14px;
            }
        }
    }

    .careerSelection {
        background: rgba(16, 16, 16, 0.9);
        justify-self: baseline;
        // width: auto;
        // height: 200px;
        padding: 20px 30px;
        width: calc(100vw - 80px);

        @include viewport(mobile) {
            max-width: 400px;
            min-width: 400px;
        }

        h5 {
            margin: 0 0 20px;
            text-transform: uppercase;
            color: #b0b0b0;
            text-align: center;
            font-size: 22px;
            animation: fadein 0.5s;
        }

        ul,
        li {
            @include reset-list;

            display: flex;
            flex-direction: row;
            justify-content: space-between;

            li {
                position: relative;
                display: flex;
                flex-direction: row;
                border-radius: 100%;
                height: 50px;
                width: 50px;
                background: transparent;
                border: 1px solid #474746;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                @include viewport(mobile) {
                    height: 60px;
                    width: 60px;
                }

                &:hover {
                    background: #ba1f7f5e;
                    border: 1px solid #ba1f7f5e;
                }

                &.active {
                    background: #ba1f7e;
                }

                &::after {
                    position: absolute;
                    top: 50%;
                    right: -34px;
                    display: block;
                    width: 32px;
                    height: 1px;
                    background: #474746;

                    @include viewport(tablet) {
                        content: '';
                    }
                }

                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
        }

        .desc {
            text-align: center;
            color: #b0b0b0;
            margin-top: 30px;

            @include viewport(mobile) {
                margin-top: 0;
            }

            @include viewport(desktop) {
                min-height: 100px;
            }
        }
    }
}

.extraWrapper {
    @include viewport(desktop) {
        margin-top: -50px;
    }
}

.careerExtraContent {
    padding-bottom: 80px;

    .cardContainer {
        column-gap: 30px;
        grid-template-columns: 1fr 1fr;
        display: grid;
        gap: 15px;
        row-gap: 15px;
        margin-top: 30px;
        transition: all 2s cubic-bezier(0.43, 1, 0.62, 1);
        animation: Fadein 0.5s;

        // .inner {
        //     outline: 1px solid red;
        // }

        @include viewport(tablet) {
            display: grid;
            grid-template-columns: repeat(12, 1fr);

            .inner {
                grid-column: span 3;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    grid-column: span 4;
                }
            }

            gap: 30px;
            row-gap: 30px;
        }

        @include viewport(desktop) {
            display: grid;
            grid-template-columns: repeat(12, 1fr);
            margin-top: 60px;

            .inner {
                grid-column: span 3;
            }
        }

        h3 {
            margin-bottom: 5px;
        }

        a {
            img {
                transition: all 0.3s;
            }

            &:hover {
                img {
                    filter: brightness(1.2);
                }
            }
        }
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
