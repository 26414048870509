@import '~shared/styles/config';

.navContainer {
    max-width: 1760px;
    margin: 0 auto;
    // padding: 0 80px;
    position: relative;
    padding: 0;

    // @media only screen and (min-width: $max-1280) {
    //     padding-left: 0;
    //     padding-right: 0;
    // }

    @media only screen and (min-width: $max-1280) {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.mobileView {
    display: none;

    @media only screen and (max-width: $max-1280) {
        display: flex;
    }
}

.desktopView {
    display: flex;

    @media only screen and (max-width: $max-1280) {
        display: none;
    }
}

.light {
    .button .closed span {
        background-color: #202020;
    }
}

.show.light {
    .button .closed span {
        background-color: #202020;
    }
}

.menuButton.light {
    .button span {
        background-color: #202020;
    }
}

.scrolled.light {
    .button span {
        background-color: #fff;
    }
}

.wrapper {
    // overflow: hidden;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    transition: all ease-in-out 0.2s;

    &.closed {
        background-color: transparent;
    }

    &.scrolled {
        background-color: rgba($www-dark, 0.9);
        backdrop-filter: blur(4px);

        // &::before {
        //     content: '';
        //     position: absolute;
        //     left: 0;
        //     bottom: 0;
        //     height: 1px;
        //     width: 100%;
        //     border-bottom: 1px solid rgba(71, 71, 70, 0.4);
        // }
    }
}

.menuButton {
    padding-left: 0;
    flex: 0 0 58px;
    display: none;

    @media only screen and (max-width: $max-1280) {
        display: flex;
    }

    .button {
        position: relative;
        top: -5px;
        padding: 0.25rem 1.25rem;
        font-size: 1.5rem;
        line-height: 1;
        background: 0 0;
        border: 1px solid transparent;
        cursor: pointer;

        @media only screen and (min-width: $max-1280) {
            padding: 0.25rem 0;
        }

        &:focus,
        &:active {
            outline: 0;
        }

        span {
            display: block;
            background-color: #fff;
            height: 2px;
            width: 25px;
            margin-top: 6px;
            margin-bottom: 6px;
            transform: rotate(0deg);
            position: relative;
            left: 0;
            opacity: 1;

            &:nth-child(1),
            &:nth-child(3) {
                transition: transform 0.35s ease-in-out;
            }
        }

        &.closed span {
            &:nth-child(1),
            &:nth-child(3) {
                width: 18px;
                transition: transform 0.35s ease-in-out, width ease-in-out 0.2s;
            }
        }

        &:not(.closed) span:nth-child(1) {
            position: absolute;
            left: 12px;
            top: 10px;
            transform: rotate(135deg);
            opacity: 0.9;
        }

        &:not(.closed) span:nth-child(2) {
            height: 12px;
            visibility: hidden;
            background-color: transparent;
        }

        &:not(.closed) span:nth-child(3) {
            position: absolute;
            left: 12px;
            top: 10px;
            transform: rotate(-135deg);
            opacity: 0.9;
        }

        &:hover {
            span {
                &:nth-child(1),
                &:nth-child(3) {
                    width: 25px;
                }
            }
        }
    }
}

.titleFiller {
    flex: 1 1 100%;
    display: none;

    @media only screen and (max-width: $max-1280) {
        display: flex;
    }
}

.titleContent {
    @media only screen and (max-width: $max-1280) {
        z-index: 25;
        position: absolute;
        // left: calc(50% - 45px);
        left: 50%;
        transform: translateX(-50%);
        margin-top: 6px;

        // Hack for IE
        @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            margin-top: -12px;
        }

        svg {
            width: 90px;
        }
    }
}

.navigation {
    // position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    font-weight: 400;

    .navRight {
        display: flex;
        align-items: center;
        margin-left: auto;
        margin-bottom: 7px;
        min-height: 70px;

        .login {
            margin-right: 40px;
            font-weight: 600;

            @media only screen and (max-width: $max-1800) {
                margin-right: 20px;
            }

            @media only screen and (max-width: $max-1440) {
                font-size: 14px;
            }
        }
    }

    .language {
        display: flex;
        align-items: center;

        @media only screen and (max-width: $max-1280) {
            display: none;
        }
    }

    &.light:not(.scrolled) {
        .language > div {
            color: #202020;
        }

        .login2 {
            div {
                color: #202020;
            }
        }

        .container {
            .login {
                a {
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
    }

    .container {
        width: 100%;
        margin: 0 auto;
        // padding: 50px 80px 0;
        padding: 40px 0 9px 0;
        transition: all ease-in-out 0.2s;
        z-index: 25;

        .logo {
            transition: all ease-in-out 0.8s;

            svg {
                transition: all ease-in-out 0.4s;
            }
        }

        @media only screen and (max-width: $max-1440) {
            padding: 40px 0 0;
        }

        @media only screen and (max-width: $max-1280) {
            display: flex;
            align-items: center;
            flex: 0 0 70px;
            padding: 0;
            // height: 70px;
        }

        // @media only screen and (min-width: 1600) {
        //     padding: 56px 80px 0;
        // }

        .login {
            transition: 0.2s ease-in-out;

            svg {
                font-size: 20px;
            }

            a {
                text-transform: uppercase;
                cursor: pointer;
                letter-spacing: 0.01em;

                &:hover {
                    color: $brand-higlight;
                    transition: 0.2s ease-in-out;
                }
            }

            .desktop {
                display: inherit;

                @media only screen and (max-width: $max-768) {
                    display: none;
                }
            }

            .onlyMobile {
                display: none;

                a {
                    font-size: 13px;
                    margin-left: 5px;
                }

                @media only screen and (max-width: $max-768) {
                    display: inherit;
                }
            }
        }

        .login2 {
            z-index: 20;
            margin-right: 20px;
            transition: 0.2s ease-in-out;

            @media only screen and (max-width: $max-1280) {
                width: unset;
            }

            .desktop {
                display: inherit;

                @media only screen and (max-width: $max-768) {
                    display: none;
                }
            }

            .onlyMobile {
                display: none;

                a {
                    font-size: 13px;
                    margin-left: 5px;
                }

                @media only screen and (max-width: $max-768) {
                    display: inherit;
                }
            }

            // &.light {
            //     color: #202020;
            // }
        }

        .signup {
            margin-left: 40px;
            margin-bottom: 7px;
            height: 40px;
            padding: 0 20px;
            font-size: 15px;
            display: none;

            &.hide {
                display: none;
            }

            @media only screen and (max-width: $max-1800) {
                margin-left: 25px;
            }
        }

        @media only screen and (max-width: $max-1280) {
            .login {
                right: 15px;
            }

            .login2 {
                right: 20px;
                top: 25px;
            }

            .signup {
                display: none;
            }
        }

        // .border {
        //     border-bottom: 1px solid #455057;
        // }

        .inner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            // padding-bottom: 39px;

            @media only screen and (max-width: $max-1280) {
                padding-bottom: 0;
                border: none;
                flex: 1 1 100%;
            }
        }
    }

    &.scrolled {
        .container {
            max-width: 1760px;
            // padding: 20px 21px 9px 18px;
            transition: all ease-in-out 0.2s;
            padding: 7px 0 2px 0;
            // background-color: #13151a;

            .inner {
                border: none;
                padding-bottom: 0;
            }

            .login {
                top: 29px;
            }

            .login2 {
                top: 29px;
            }

            .signup {
                display: inline-flex;
            }

            .userName {
                color: #fff;
            }

            @media only screen and (min-width: $min-1280) {
                .logo {
                    svg {
                        width: 110px;
                    }
                }
            }

            @media only screen and (max-width: $max-1280) {
                padding: 0;

                .login {
                    top: 22px;
                }

                .login2 {
                    top: 22px;
                }

                .signup {
                    display: none;
                }
            }
        }
    }

    &.open,
    &.closed {
        transition: all ease-in-out 0.2s;
        // height: 70px;
        // min-height: 70px;
        // position: absolute;
        width: 100%;
        // background-color: #13151a;

        &.scrolled {
            // height: 75px;
            // min-height: 75px;
            // position: fixed;
            // background-color: #13151a;
            height: auto;
            width: auto;
            // transition-delay: 1s;

            @media only screen and (min-width: $max-1280) {
                padding: 8px 0 5px 0;
            }
        }
    }

    &.light {
        .login {
            a {
                color: #202020;

                &:hover {
                    color: #fff;
                }
            }
        }

        .inner {
            &.border {
                // border-bottom: 1px solid rgba(0, 0, 0, 0.2);

                @media only screen and (max-width: $max-1280) {
                    border: none;
                }
            }

            nav > div > a {
                color: #202020;
                margin-bottom: 7px;
                @include font-semibold();
            }
        }

        &.scrolled {
            .login {
                color: #fff;
            }

            nav > div > a {
                color: #fff;
            }
        }
    }

    &.open {
        @media only screen and (max-width: $max-1280) {
            .container {
                z-index: 26;
                background-color: $www-dark;
                // transition: none;
                position: static;
            }
        }

        @media only screen and (max-width: $max-840) {
            .container .account .signup {
                display: none;
            }
        }
    }

    &.closed {
        @media only screen and (max-width: $max-1280) {
            .container {
                transition: all ease-in-out 0.2s,
                    background-color ease-in-out 0.2s 0.2s;
            }

            &.scrolled {
                padding: 0;

                .container {
                    transition: all ease-in-out 0.2s,
                        background-color ease-in-out 0s;
                }
            }
        }
    }

    @media only screen and (max-width: $max-1280) {
        &.closed {
            height: 70px;
            min-height: 70px;

            &.scrolled {
                height: 70px;
                min-height: 70px;
            }
        }
    }

    &.show {
        top: 0;
    }

    &.hide {
        top: -175px;

        @media only screen and (max-width: $max-1280) {
            &.open {
                top: 0;
            }
        }
    }
}

.userName {
    margin-right: 10px;
    font-size: small;
    // font-style: italic;
    letter-spacing: 0.01em;
    color: #fff;

    .light & {
        color: #202020;
    }
}
