@import '~shared/styles/config';

.socialIcon {
    width: 32px;
    height: 32px;
    background: #8c8c8c;
    position: relative;
    border-radius: 100%;
    transition: 0.2s ease-in-out;

    a {
        @include reset-anchor;

        position: absolute;
        width: 100%;
        height: 100%;

        .icon {
            @include centerer;

            font-size: 20px;
            color: #1d1e20;
            transition: 0.2s ease-in-out;
        }
    }

    &.incolor {
        .icon {
            color: #fff;
        }

        &.facebook {
            background: $social-fb;
        }

        &.twitter {
            background: $social-t;
        }

        &.youtube {
            background: $social-yt;
        }

        &.google {
            background: $social-g;
        }

        &.twitch {
            background: $social-tw;
        }

        &.instagram {
            background: $social-ig;
        }

        &.vk {
            background: $social-vk;
        }

        &.reddit {
            background: $social-reddit;
        }
    }

    &:hover {
        background: $brand-blue;

        &.facebook {
            background: $social-fb;
        }

        &.twitter {
            background: $social-t;
        }

        &.youtube {
            background: $social-yt;
        }

        &.google {
            background: $social-g;
        }

        &.twitch {
            background: $social-tw;
        }

        &.instagram {
            background: $social-ig;
        }

        &.vk {
            background: $social-vk;
        }

        &.reddit {
            background: $social-reddit;
        }

        .icon {
            color: #fff;
        }
    }

    // This is a hack needed since
    // IE does not support justify-content: space-evenly
    &::before, &::after {
        content: '';
    }
}
