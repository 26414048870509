@import '~shared/styles/config';

.container {
    @include container();

    .full {
        max-width: none;

        @include viewport(tablet) {
            max-width: none;
        }

        @include viewport(desktop) {
            max-width: none;
        }
    }

    &.xlWide {
        max-width: 1680px;
        // padding: 0 80px;
        position: relative;
    }
}

.wide {
    max-width: 1920px;
    padding: 0;
}
