@import '~shared/styles/config';

.featured {
    background: $www-night-black;
    padding: 30px 20px;

    &__content {
        display: flex;
        align-items: center;
        padding: 30px 0;
        border-bottom: 1px solid #000;

        a {
            transition: 0.3s ease-in-out;
        }

        &:last-child {
            border-bottom: 0;
            padding-bottom: 0;
        }

        &:hover {
            a {
                color: $www-primary;
            }

            .imgwrapper::after {
                opacity: 0.05;
            }
        }
    }

    &__imgwrapper {
        position: relative;
        margin-right: 20px;

        &::after {
            content: '';
            background: #fff;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.3s ease;
            pointer-events: none;
        }
    }

    &__img {
        max-width: none;
    }

    &__content:hover & {
        &__imgwrapper::after {
            opacity: 0.05;
        }
    }
}

@include viewport(mobile) {
    .featured {
        padding: 30px;
    }
}
