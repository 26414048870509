@import '~shared/styles/config';

.faq {
    margin: 50px 0;

    @include viewport(mobile) {
        margin: 120px 0;
    }

    .intro {
        margin-bottom: 50px;
    }

    .content {
        // max-width: 90%;

        @include viewport(mobile) {
            max-width: 90%;
        }

        h3 {
            font-weight: $font-medium;
            margin-bottom: 10px;
            font-size: 22px;
        }

        p {
            margin: 10px 0 30px;
        }
    }
}
