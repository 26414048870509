@import '~shared/styles/config';

.platforms {
    display: none;
    margin-top: 25px;
    opacity: 0;
    animation: opacity 0.7s;
    animation-fill-mode: forwards;
    animation-delay: 2.3s;
    margin-bottom: 60px;

    @include viewport(mobile) {
        display: inline-block;
        margin-bottom: 10px;
        margin-top: 20px;
    }

    .inner {
        // background: rgba($www-night-black, 0.8);
        display: inline-flex;
        align-items: center;
    }

    .text {
        font-size: 15px;
        text-transform: uppercase;
        font-weight: $font-semibold;
        color: $www-silver-gray;
    }

    .icons {
        display: flex;
        align-items: center;
        margin-left: 0px;
        backface-visibility: hidden;

        .icon {
            display: block;
            transition: transform 0.2s;
            margin-right: 15px;

            .tooltip {
                background: rgba($www-night-black, 0.8);
                padding: 5px 10px;
                // max-width: 350px;
                // transition: opacity 0.2s;
            }

            .color {
                transition: $transition;
            }

            &:hover {
                transform: scale(1.075);
                // filter: brightness(0.8);

                .color {
                    fill: #fff;
                }
            }
        }
    }
}

@keyframes opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
