@import '~shared/styles/config';

// .content {
//     padding: 80px 0;
// }

.header {
    position: absolute;
    width: 100%;
    min-height: 400px;

    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 197px;
        background-image: url('//web.ccpgamescdn.com/aws/eveonline/images/transparency-transition.png');
        background-repeat: repeat-x;
        pointer-events: none;
    }

}

.container {
    position: relative;
}

@include viewport(mobile) {
    .header {
        min-height: 600px;
    }
}

@include viewport(desktop) {
    // .content {
    //     padding: 160px 0;
    // }

    .header {
        min-height: 700px;
    }
}

// @include viewport(desktop) {
//     .header {
//         min-height: 1080px;
//     }
// }