@import '~shared/styles/config';

.heading {
    text-align: center;
    transform: translateZ(0);

    .inner {
        display: inline-block;
        position: relative;
        margin-top: 30px;
        padding-bottom: 15px;
        width: 100%;

        h3,
        h4 {
            margin: 0;
            font-size: 24px;
            line-height: 55px;
            display: inline-block;
            overflow: hidden;
            position: relative;
            // text-transform: uppercase;
            transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
            transform: translateY(0);
            transition-delay: 0.6s;
            opacity: 0;
            @include font-medium();
        }

        h3 {
            letter-spacing: 0;
            text-transform: uppercase;

            @include viewport(mobile) {
                font-size: 36px;
            }
        }

        h4 {
            font-size: 22px;
            line-height: 34px;
            text-transform: uppercase;

            @include viewport(mobile) {
                font-size: 26px;
                margin-bottom: 20px;
            }

            display: block;
            letter-spacing: 0.05em;
            @include font-light();
        }

        .line {
            background: #154f5b;
            height: 3px;
            width: 0;
            margin: 0 auto;
            transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
            transition-delay: 0.6s;
            transition-property: left right;
        }

        &.active {
            h3,
            h4 {
                opacity: 1;
                transform: translateY(-20px);
            }

            .line {
                width: 80%;

                @include viewport(mobile) {
                    width: 340px;
                }
            }
        }
    }
}
