@import '~shared/styles/config';

.filter {
    color: $www-silver-gray;
}

.filter_title {
    font-size: 18px;
    text-transform: uppercase;
    white-space: nowrap;
}

.date_group {
    display: flex;
    margin-top: 10px;
    overflow-x: auto;
}

.date {
    // flex-direction: column;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: 2px;
    cursor: pointer;
    margin: 0 10px 0 0;

    &:hover,
    &.active {
        color: $www-primary;
    }
}

@include viewport(tablet) {
    .filter {
        color: $www-silver-gray;
        text-align: right;
        margin-left: auto;
        margin-top: 50px;
    }

    .date_group {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

    .date {
        font-size: 16px;
        margin: 2px 0 0;
    }
}

@include viewport(tabletmax) {
    .overflow {
        position: relative;

        .date_group {
            background: rgba($www-night-black, 0.8);
            padding: 10px;

            &::after {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                width: 70px;
                background-image: linear-gradient(
                    to left,
                    $www-night-black,
                    transparent
                );
                z-index: 1;
                pointer-events: none;
            }
        }

        .date:last-child {
            padding-right: 50px;
        }
    }
}
