@import '~shared/styles/config';

.specialOfferCount {
    // red dot with a number in the middle
    .inner {
        border-radius: 50%;
        display: flex;
        font-size: 12px;
        font-weight: 700;
        height: 20px;
        justify-content: center;
        line-height: 20px;
        position: absolute;
        right: 0;
        top: 0;
        width: 20px;
    }
}

.specialBubble {
    background-color: #d50f0ff2;
    border-radius: 50%;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    width: 10px;
    height: 10px;
    justify-content: center;
    line-height: 20px;
    position: absolute;
    left: -8px;
    top: -3px;
    opacity: 0;
    transition: opacity 0.5s ease-in;

    &.fadeIn {
        opacity: 1;
    }
}
