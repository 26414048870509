@import '~shared/styles/config';

.tags {
    flex-basis: 100%;

    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;

        a {
            transition: all 0.25s;
        }

        li {
            display: inline-block;
            padding: 7px 5px;

            a {
                background-color: #29353a;
                border-radius: 28px;
                padding: 5px 10px 4px 10px;
                text-transform: uppercase;
                font-size: 13px;
                color: #5ccbcb;
                transition: all 0.2s;
                font-weight: $font-semibold;
                letter-spacing: 0.02em;

                &:hover {
                    background-color: #353e42;
                }
            }
        }

        .allTags {
            cursor: pointer;
            margin-left: 20px;
            outline: none;
            transition: 0.2s ease;

            &:hover {
                color: $brand-higlight;
            }

            svg {
                font-size: 30px;
                position: relative;
                top: 4px;
                vertical-align: top;
            }
        }

        .upcoming {
            margin-right: 20px;
            color: $brand-higlight;
            font-size: 13px;
            font-weight: $font-semibold;

            &:hover {
                color: $brand-primary;
            }
        }
    }
}

.tags.list {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    background: $www-night-black;
    padding: 11px 20px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s ease;

    &.active {
        opacity: 1;
        visibility: visible;
    }

    .close {
        outline: none;
        margin-left: 40px;
        position: relative;
        top: 4px;

        svg {
            font-size: 22px;
            cursor: pointer;
            transform: rotate(90deg);
        }

        path {
            fill: $www-silver-gray;
        }
    }
}

@include viewport(tabletmax) {
    .tags {
        .preview li,
        .upcoming {
            display: none;
        }
    }

    .tags.list {
        padding: 10px;
    }
}
