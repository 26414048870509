@import '~shared/styles/config';

.discord {
    text-align: center;
    background: #000
        url('//web.ccpgamescdn.com/aws/eveonline/images/backgrounds/download.jpg')
        no-repeat top center;
    background-attachment: fixed;
    background-size: cover;
    padding: 230px 0 230px;

    .buttonBar {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .btn {
        transition: all 0.2s ease-in-out;
        &::before {
            transition: all 0.2s ease-in-out;
        }

        &.btnDisabled {
            background: $www-silver-gray;
            > span {
                color: $www-steel-gray;
            }
            &::before {
                background: $www-silver-gray;
            }
        }
    }

    .login {
        margin-top: 20px;
    }

    svg[data-icon='discord'] {
        color: #5865f2;
    }

    .linkResult {
        display: flex;
        text-align: center;
        justify-content: center;
    }

    .linking {
        // display: flex;
        // align-items: center;

        .successTitle {
            display: flex;
            align-content: stretch;
            justify-content: center;
            align-items: center;

            span {
                color: #30b2e6;
            }
        }

        .success {
            font-size: 100px;
            color: green;
        }
    }

    .characters {
        padding: 10px;

        ul {
            display: flex;
            gap: 5%;
            padding: 0;
            align-items: center;
            justify-content: center;

            li {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                width: 30%;
                height: 100%;
                cursor: pointer;
            }
        }
    }

    .content {
        > h3 {
            margin-top: 3.5rem;
            margin-bottom: 0.5rem;
        }
    }
    .footer {
        padding-top: 4rem;
        padding-bottom: 1rem;
    }
    a {
        text-decoration: none;
        color: $www-primary;
    }
}

@media only screen and (max-width: $max-960) {
    .discord {
        .characters {
            ul {
                flex-direction: column;
                width: 100%;
                gap: 2rem;
                li {
                    width: 75%;
                }
            }
        }
    }
}
