@import '~shared/styles/config';

.table {
    overflow-x: auto;
    margin: 40px 0;
    border-spacing: 2px;
    width: 100%;

    p {
        margin: 0;
        font-size: inherit;
        font-weight: inherit;
    }

    th,
    td {
        padding: 5px;

        @include viewport(mobile) {
            padding: 20px;
        }
    }

    th {
        font-weight: $font-semibold;
        background-color: $www-space-gray;
        padding: 20px;
    }

    td {
        background-color: $www-night-black;
    }
}
