@import '~shared/styles/config';

.video {
    margin: 50px 0;

    @include viewport(mobile) {
        margin: 120px 0;
    }

    .vidWrapper {
        display: grid;
        grid-template-columns: 1fr;

        @include viewport(mobile) {
            grid-template-columns: 2fr 1fr;
        }

        @include viewport(tablet) {
            grid-template-columns: 2fr 1fr;
        }

        @include viewport(desktop) {
            grid-template-columns: 13fr 4fr;
        }

        grid-column-gap: 0;
        margin-top: 20px;

        @include viewport(mobile) {
            margin-top: 40px;
        }

        .inner {
            position: relative;
            padding-bottom: 56.25%;
            height: 0;
            overflow: hidden;
            max-width: 100%;

            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }

        .chapters {
            @include viewport(mobileMax) {
                display: none;
            }

            @include styled-scrollbar(#000, #c0c0c0, 5px);

            max-height: 553px;
            overflow-y: auto;
            overflow-x: auto;
            padding: 20px 20px 0 40px;
            // background: #212121;

            span {
                font-size: 26px;
                text-transform: uppercase;
                font-weight: 500;
            }

            ul {
                list-style: square;
                margin: 20px 0;
                padding: 0 0 0 20px;
            }

            li {
                // @include reset-list;
                transition: all 0.3s;
                cursor: pointer;
                padding-bottom: 10px;
                font-size: 18px;
                color: #c0c0c0;
                font-weight: 400;

                &.active {
                    color: $www-theme-highlight;
                }

                &::marker {
                    color: $www-theme-highlight;
                }

                &:hover {
                    color: $www-theme-highlight;
                }
            }
        }
    }
}
