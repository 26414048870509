@import '../../../article/ArticlesShared';

/********************************************
/Articles/Single
********************************************/

/* stylelint-disable-next-line */
:global {
    .cookieconsent-optout-marketing,
    .cookieconsent-optin-marketing {
        display: none;
    }
}

.articles_single {
    background-image: url('//web.ccpgamescdn.com/aws/eveonline/images/backgrounds/stars.jpg');
    background-size: cover;
    background-attachment: fixed;

    strong {
        @include font-medium();
    }

    .cookieConsentWrapper {
        border: 1px solid #3c3c3c;
        padding: 20px;
        font-size: 20px;
        background-color: rgba(37, 37, 37, 0.4);

        a {
            cursor: pointer;
        }
    }

    .article_title {
        padding-top: 10px;
        padding-bottom: 10px;

        @include viewport(tablet) {
            padding-bottom: 60px;
        }

        h2 {
            font-size: 6vw;

            @include viewport(mobile) {
                font-size: 46px;
            }

            padding-bottom: 10px;
            @include font-semibold();
        }

        .author_date {
            color: #979797;
        }
    }

    hr {
        border-color: #777;
    }

    .player {
        @include fit;
    }

    .close {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
        opacity: 0.7;
        transition: 0.2s ease-in-out;
        font-size: 36px;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }
    }

    .content {
        p,
        li {
            font-size: 16px;
            @include font-light();

            @include viewport(tablet) {
                font-size: 20px;
            }

            strong {
                @include font-medium();
            }
        }

        h2 {
            margin: 20px 0 10px;
        }

        h3 {
            font-size: 22px;
        }

        img {
            margin-bottom: 10px;

            @include viewport(tablet) {
                margin-bottom: 40px;
            }
        }

        a {
            color: $brand-higlight;
            transition: color ease-in-out 0.15s;

            &:hover {
                color: lighten($brand-higlight, 15);
            }
        }

        /*****************************************
            Table styles
        *****************************************/
        .table {
            overflow-x: auto;
        }

        table {
            margin: 40px 0;
            border-spacing: 0;
            width: 100%;
            // position: absolute;
            top: 0;
            left: 0;

            thead {
                tr:hover {
                    background: none;
                }

                th {
                    &:first-child {
                        // width: 16%;
                    }

                    @include font-semibold();
                    background-color: rgba(59, 62, 68, 0.5);
                }
            }

            td,
            th {
                background-color: rgba(23, 27, 35, 0.8);
                padding: 5px 10px;

                @include viewport(mobile) {
                    padding: 20px 30px;
                }

                text-align: left;
            }

            tr:hover {
                background-color: #2d3339;
            }

            td {
                @include font-light();

                // border-bottom: 1px solid rgba(59, 62, 68, 0.5);

                &:first-child {
                    // @include font-medium();
                }

                &:nth-child(1),
                &:nth-child(2) {
                    border-right: 1px solid rgba(59, 62, 68, 0.5);
                }
            }
        }

        /*****************************************
            Table styles end
        *****************************************/
    }

    .banner {
        // min-height: 400px;
        display: flex;
        align-items: flex-end;
        width: 100%;
        // background-color: rgba(#000, 0.25);
        // background-blend-mode: overlay;
        padding-bottom: 30px;
        position: relative;
        overflow: hidden;

        // @include bg-img;

        // .gradient {
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 50%;
        //     background-image: linear-gradient(0deg, rgba(#000, 0.3),transparent);
        // }

        .banner_img {
            height: 100%;
            // object-fit: cover;

            // @include centerer;
        }

        h2 {
            line-height: 1.2;
            margin-top: 15px;
            font-size: 28px;
            @include font-semibold();
        }

        .container {
            flex: 1;
            position: relative;
        }

        .play_btn {
            // display: none;

            @include centerer;

            opacity: 0.7;

            &:hover {
                opacity: 1;
                cursor: pointer;
            }

            img {
                width: 80%;
                margin-bottom: 0;
            }

            @include viewport(tablet) {
                img {
                    width: 100%;
                }
            }
        }

        .youtube {
            display: none;
            position: absolute;
            top: 40px;
            right: 40px;

            p {
                font-size: 18px;
                display: flex;
            }

            .youtube_icon {
                font-size: 32px;
                margin-left: 15px;
            }
        }
    }

    .author_date {
        font-size: 16px;
        color: #fff;
    }

    // .social_wrapper {
    //     padding-left: 0;
    // }

    .social_items {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        // padding-left: 30px;
        @include viewport(tablet) {
            flex-direction: column;
        }

        a:first-child {
            margin-right: 10px;

            @include viewport(tablet) {
                margin-right: 0;
            }
        }
    }

    .article {
        padding: 60px 0;

        .content {
            h2 {
                font-size: 32px;
                font-weight: 600;
            }

            p {
                font-size: 18px;
                margin-bottom: 20px;
                line-height: 30px;
            }
        }
    }

    .forums_discussion {
        border-bottom: 1px solid #565b5f;
        padding-bottom: 30px;
        @include font-regular();

        a {
            color: $brand-higlight;
        }
    }

    .social_items {
        a {
            display: flex;
            height: 25px;
            width: 25px;
            justify-content: center;
            align-items: center;
            background: #4e5f6a;
            margin-bottom: 10px;

            @include transition(all 0.4s ease);

            @include viewport(tablet) {
                height: 43px;
                width: 43px;
            }

            svg {
                color: rgb(24, 41, 55);
                font-size: 15px;

                @include viewport(tablet) {
                    font-size: 23px;
                }
            }

            &:hover {
                background: #3b5998;

                &.twitter {
                    background: #1ea1f3;
                }

                svg {
                    color: #fff;
                }
            }
        }
    }
}

@include viewport(tablet) {
    .articles_single {
        background-color: rgba(#1a2730, 1);

        .banner {
            // min-height: 600px;
            // max-width: 90%;
            // margin: 0 auto;
            // padding-bottom: 40px;

            h2 {
                font-size: 36px;
                margin-top: 15px;
            }

            .play_btn {
                display: block;
            }

            .youtube {
                top: auto;
                bottom: 60px;
                right: 60px;
            }
        }

        .backlinks {
            padding: 20px 0 20px 15px;
        }

        .article {
            padding: 0 0 20px;

            .content {
                img {
                    width: 100%;
                    margin-bottom: 40px;
                }
            }
        }
    }
}

@include viewport(mobilemax) {
    .articles_single {
        .banner {
            min-height: 300px;
        }

        .article {
            padding: 40px 0;

            .content {
                p {
                    font-size: 18px;
                }
            }

            .play_btn {
                img {
                    width: 80%;
                }
            }

            .social_items {
                flex-direction: row;
                margin-bottom: 20px;

                .social {
                    margin-right: 20px;
                }
            }
        }
    }
}
