@import '~shared/styles/config';

.content {
    margin-top: 1vw;
    margin-bottom: 10vw; // ~200px on 1920w

    .news {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 30px;
        text-align: left;
        margin-top: 4vw;

        @media only screen and (max-width: '1080px') {
            grid-template-columns: 1fr;
            grid-gap: 15px;
        }

        .item {
            background-color: rgba(255, 255, 255, 0.1);
            padding: 40px;

            p {
                margin: 0;

                a {
                    color: $www-thermal-orange;
                }
            }
        }
    }
}
