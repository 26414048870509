@import '~shared/styles/config';

.slider {
    // @import '~shared/styles/carousel-reset';

    position: relative;
    overflow: hidden;
}

// Progress bar
.dots {
    display: flex !important;
    justify-content: center;
    max-width: 700px;
    margin: 20px auto 0;
    padding: 0;

    li {
        width: 80px;
        list-style-type: none;
        margin: 0 5px;
    }
}

.outer {
    padding: 20px 0;
    cursor: pointer;
}

.progress {
    margin: 0 auto;
    height: 2px;
    background: rgba(#fff, 0.2);
    bottom: 0;
    cursor: pointer;
    max-width: 80px;
    transition: opacity 0.3s;

    .bar {
        width: 0%;
        max-width: 100%;
        height: 2px;
        background: $www-theme-highlight;
        transition-timing-function: linear;
        opacity: 0;
    }

    &.active {
        opacity: 1;

        .bar {
            opacity: 1;
        }
    }
}
