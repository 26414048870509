@import '~shared/styles/config';

.quotesWrapper {
    // padding: 50px 0 20px;
    margin-top: auto;
    margin-top: 20px;

    @include viewport(tablet) {
        // margin-bottom: 30px;
        margin-top: auto;
    }

    .quotes {
        @import '~shared/styles/carousel-reset';

        display: none;

        @include viewport(mobile) {
            display: block;
        }

        position: relative;
        overflow: hidden;
        // padding: 30px 0 0;
        max-width: 800px;

        .quote {
            text-align: left;
            outline: none;

            p {
                font-size: 22px;
                margin: 0;
                font-weight: $font-light;
                line-height: 1.2;
                cursor: default;
                min-height: 90px;
                display: flex;
                align-items: flex-end;
            }

            .imgContainer {
                min-height: 65px;
                display: flex;
                align-items: flex-start;
            }

            img {
                margin-top: 20px;
                max-height: 40px;
            }
        }

        // stylelint-disable-next-line
        :global {
            .slick-track {
                display: flex;
                flex-grow: 1;
                // align-items: center;
                align-items: flex-start;

                .slick-slide {
                    display: flex;
                    justify-content: left;
                }
            }
        }

        .dots {
            display: flex !important;
            justify-content: center;
            max-width: 700px;
            // margin: 30px auto 0;
            margin: 20px auto 0;
            padding: 0;

            li {
                width: 80px;
                list-style-type: none;
                margin: 0 5px;
            }
        }

        /* progress bar */
        .outer {
            padding: 20px 0;
            cursor: pointer;
        }

        .progress {
            // width: 100%;
            margin: 0 auto;
            height: 2px;
            // background: #fff;
            background: rgba(#fff, 0.2);
            bottom: 0;
            cursor: pointer;
            max-width: 80px;
            // opacity: 0.3;
            transition: opacity 0.3s;

            .bar {
                width: 0%;
                max-width: 100%;
                height: 2px;
                background: $www-theme-highlight;
                transition-timing-function: linear;
                //
                opacity: 0;
            }

            &.active {
                opacity: 1;
                // transition: opacity 0.2s;

                .bar {
                    opacity: 1;
                }
            }
        }

        @include viewport(mobile) {
            .quote img {
                margin: 15px 0 0;
                max-height: 40px;
            }
        }

        @include viewport(tablet) {
            padding: 50px 0 10px;

            .quote p {
                font-size: 18px;
            }
        }

        @include viewport(desktop) {
            .quote p {
                font-size: 22px;
            }
        }
    }
}
