@mixin container() {
    margin: 0 auto;
}

@mixin buttonBase() {
    position: relative;
    text-transform: uppercase;
    padding: 0 30px;
    height: 45px;
    min-width: 118px;
    font-size: 16px;
    letter-spacing: 0.08em;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
    line-height: 0;
    font-weight: 600;
    clip-path: polygon(
        0% 0,
        0 0%,
        100% 0%,
        100% 0,
        100% calc(100% - 8px),
        calc(100% - 8px) 100%,
        0 100%,
        0% calc(100% - 8px)
    );
    background-color: $www-primary;
    background: linear-gradient(180deg, #30b2e6 0%, #2b9ecc 100%), #30b2e6;
    color: #fff;
    border: 0;
    white-space: nowrap;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

@mixin buttonTransparent() {
    position: relative;
    background: transparent;
    color: #fff;

    &::before,
    &::after {
        content: '';
        display: block;
        background: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &::before {
        clip-path: polygon(
            0 0,
            100% 0,
            100% calc(100% - 11px),
            calc(100% - 11px) 100%,
            0 100%,
            0 1.5px,
            1.5px 1.5px,
            1.5px calc(100% - 1.5px),
            calc(100% - 11.5px) calc(100% - 1.5px),
            calc(100% - 1.5px) calc(100% - 11.5px),
            calc(100% - 1.5px) 1.5px,
            0 1.5px
        );
    }

    &::after {
        clip-path: polygon(
            0 0,
            100% 0,
            100% calc(100% - 10px),
            calc(100% - 10px) 100%,
            0 100%
        );
        opacity: 0;
        z-index: -1;
        transition: 0.2s ease-in-out;
    }

    &:hover {
        color: #101010;

        &:after {
            opacity: 1;
        }
    }
}

@mixin font-headline-extra-large() {
    @include font-medium();

    font-size: 120px;
    line-height: 144px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $www-bright-white;
}

@mixin font-headline-large() {
    @include font-medium();

    font-size: 96px;
    line-height: 101px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: $www-bright-white;
}

@mixin font-headline-medium() {
    @include font-medium();

    font-size: 72px;
    line-height: 76px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $www-bright-white;
}

@mixin font-headline-regular() {
    @include font-medium();

    font-size: 48px;
    line-height: 51px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $www-bright-white;
}

@mixin font-headline-small() {
    @include font-medium();

    font-size: 36px;
    line-height: 39px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $www-bright-white;
}

@mixin font-headline-extra-small() {
    @include font-medium();

    font-size: 26px;
    line-height: 34px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: $www-bright-white;
}

@mixin headline($size) {
    @if $size == 'xl' {
        @include font-headline-extra-large();
    } @else if $size == 'l' {
        @include font-headline-large();
    } @else if $size == 'm' {
        @include font-headline-medium();
    } @else if $size == 's' {
        @include font-headline-small();
    } @else if $size == 'xs' {
        @include font-headline-extra-small();
    } @else {
        @include font-headline-regular();
    }
}

@mixin font-tagline-large() {
    @include font-light();

    font-size: 36px;
    line-height: 43px;
    letter-spacing: 0.02em;
    // text-transform: uppercase;
    color: $www-bright-white;
}

@mixin font-tagline-regular() {
    @include font-light();

    font-size: 26px;
    line-height: 31px;
    letter-spacing: 0.06em;
    // text-transform: uppercase;
    color: $www-bright-white;
}

@mixin font-tagline-small() {
    @include font-light();

    font-size: 18px;
    line-height: 20px;
    letter-spacing: 0.02em;
    // text-transform: uppercase;
    color: $www-bright-white;
}

@mixin tagline($size) {
    @if $size == 'l' {
        @include font-tagline-large();
    } @else if $size == 's' {
        @include font-tagline-small();
    } @else {
        @include font-tagline-regular();
    }
}

// Mixins for all font weight variants, including japan special font weight
@mixin font-family() {
    font-family: var(--font-family);
}

@mixin font-light() {
    font-weight: var(--font-weight-light);
}

@mixin font-regular() {
    font-weight: var(--font-weight-regular);
}

@mixin font-medium() {
    font-weight: var(--font-weight-medium);
}

@mixin font-semibold() {
    font-weight: var(--font-weight-semibold);
}

// @mixin font-content-regular() {
//     @include font-regular();
//     font-size: 22px;
//     line-height: 34px;
//     letter-spacing: -0.01em;
// }

// @mixin font-content-small() {
//     @include font-regular();
//     font-size: 18px;
//     line-height: 30px;
// }

@mixin content($size) {
    @if $size == 's' {
        @include font-content-small();
    } @else {
        @include font-content-regular();
    }
}

@mixin transition($args...) {
    -webkit-transition: $args;
    -moz-transition: $args;
    -ms-transition: $args;
    -o-transition: $args;
    transition: $args;
}

// Useful for centering stuff inside relative container
@mixin centerer() {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

@mixin bg-img() {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

// Useful for YT videos and overlays f.x
@mixin fit() {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

// Media queries
@mixin viewport($media) {
    @if $media == xsMax {
        @media only screen and (max-width: $screen-xs-max) {
            @content;
        }
    }

    @if $media == mobileMax {
        @media only screen and (max-width: $screen-sm-max) {
            @content;
        }
    }

    @if $media == mdMax {
        @media only screen and (max-width: $screen-md-max) {
            @content;
        }
    }

    @if $media == xs {
        @media only screen and (min-width: $screen-xs) {
            @content;
        }
    }

    @if $media == mobile {
        @media only screen and (min-width: $screen-sm) {
            @content;
        }
    } @else if $media == tablet {
        @media only screen and (min-width: $screen-md) {
            @content;
        }
    } @else if $media == tabletmax {
        @media only screen and (max-width: $screen-md) {
            @content;
        }
    } @else if $media == tabletmin {
        @media only screen and (min-width: $screen-md) {
            @content;
        }
    } @else if $media == desktop {
        @media only screen and (min-width: $screen-lg) {
            @content;
        }
    } @else if $media == desktopMax {
        @media only screen and (max-width: $screen-lg) {
            @content;
        }
    } @else if $media == xlargeDesktopMax {
        @media only screen and (max-width: $screen-xlg) {
            @content;
        }
    } @else if $media == xlargeDesktopUp {
        @media only screen and (min-width: $screen-xlg) {
            @content;
        }
    } @else if $media == max1440 {
        @media only screen and (max-width: 1440px) {
            @content;
        }
    } @else if $media == oldIE {
        @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            @content;
        }
    } @else if $media == IE {
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            @content;
        }
    }
}

@mixin container() {
    margin: 0 auto;
    padding: 0 $container-gutter-mobile;
    max-width: $page-width + $container-gutter-mobile * 1;

    @include viewport(mobile) {
        max-width: $page-width + $container-gutter-mobile * 2;
    }

    @include viewport(tablet) {
        padding: 0 $container-gutter-tablet;
        max-width: $page-width + $container-gutter-tablet * 2;
    }

    @include viewport(desktop) {
        padding: 0 $container-gutter-desktop;
        max-width: $page-width + $container-gutter-desktop * 2;
    }
}

// Used with position relative on parent
@mixin triangle-corners($color) {
    &::before {
        content: '';
        width: 100%;
        height: 0;
        position: absolute;
        top: 0;
        left: 0;
        border-bottom: 8px solid transparent;
        border-left: 8px solid $color;
        border-right: 8px solid $color;
    }

    &::after {
        content: '';
        width: 100%;
        height: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        border-top: 8px solid transparent;
        border-left: 8px solid $color;
        border-right: 8px solid $color;
    }
}

@mixin gradient($direction: bottom, $width: 100%, $height: 50%) {
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: $width;
        height: $height;
        background-image: linear-gradient(
            to $direction,
            rgba(#000, 0%),
            rgba(#000, 0.38) 44%,
            rgba(#000, 0.67) 100%
        );
    }
}

@mixin gradient-transition(
    $direction: bottom,
    $position: left,
    $width: 100%,
    $height: 50%
) {
    &::before,
    &::after {
        content: '';
        position: absolute;
        bottom: 0;
        // left: 0;
        width: $width;
        height: $height;
        background: linear-gradient(
            to $direction,
            rgba(#000, 0%),
            rgba(#000, 0.28) 44%,
            rgba(#000, 0.52) 100%
        );

        @if $position == right {
            right: 0;
            left: auto;
        }
    }

    &::after {
        // width: 50%;
        @if $direction == bottom {
            height: $height * 2;
        } @else if $direction == left or $direction == right {
            width: $width * 2;
        }

        transition: opacity 0.2s linear;
        opacity: 0;
    }

    &:hover::after {
        opacity: 1;
    }
}

@mixin styled-scrollbar($track, $thumb, $width: 8px) {
    &::-webkit-scrollbar {
        width: $width;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-thumb:window-inactive {
        background-color: $thumb;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 0;
    }

    &::-webkit-scrollbar-track {
        border-radius: 0;
        background: $track;
    }

    scrollbar-color: $thumb $track;
    scrollbar-width: thin;
}

@mixin arrow-hover {
    svg[data-name='arrow-right'] {
        transition: transform 0.2s ease-in-out;
    }

    &:hover {
        svg[data-name='arrow-right'] {
            transform: translateX(5px);
        }
    }
}

@mixin star-fade {
    &::before {
        content: '';
        position: absolute;
        bottom: -3px;
        width: 100%;
        height: 197px;
        background-image: url(//web.ccpgamescdn.com/aws/eveonline/images/transparency-transition.png);
        background-repeat: repeat-x;
        pointer-events: none;
    }
}

@mixin button-hover($bgColor: #ddd) {
    &::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0;
        width: 110%;
        height: 102%;
        background: $bgColor;
        filter: brightness(1.07);
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
        transition: transform 0.4s ease, opacity 0.4s ease;
        pointer-events: none;
        z-index: -1;
    }
}

// DLP mixins
@mixin dlp-theme-btn {
    background: var(--dlp-cta-bg);

    &::before {
        background: inherit;
    }

    span {
        color: var(--dlp-cta-color);
    }
}

@mixin line-heading($color) {
    display: flex;
    align-items: center;
    line-height: 1.2;

    &::before {
        content: '';
        width: 2px;
        height: 90%;
        min-height: 25px;
        background: $color;
        margin-right: 15px;
    }
}
