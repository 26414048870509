@import '~shared/styles/config';

:global {
    .slide-pane__overlay.overlay-after-open {
        background-color: rgba(#000, 0.85);
        transition: background-color 0.1s;
    }

    .slide-pane__content {
        padding: 30px 0;
    }

    .slide-pane__overlay {
        z-index: 1000;
    }
}

.pane {
    width: 100%;
    height: 80vh;
    margin-top: 20vh;

    background-color: rgba(#000, 0.5);

    a {
        color: var(--dlp-link-color);
    }
    table {
        tbody {
            tr {
                td {
                    min-width: 105px;
                    padding-right: 5px;
                }
            }
        }
    }
}

.back {
    @include reset-button;

    cursor: pointer;
    margin-bottom: 10px;

    .arrow {
        margin-left: 0;
        margin-right: 8px;
    }

    svg {
        transition: $transition;
    }

    &:hover {
        svg {
            transform: translateX(-5px) rotate(180deg);
        }
    }

    svg {
        transform: rotate(180deg);
    }
}
