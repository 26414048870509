@import '~shared/styles/config';

// Variables
$text-arrow-space: 12px;
$shaft-width: 30px;
$shaft-thickness: 2px;
$arrow-head-width: 8px;
$arrow-head-thickness: $shaft-thickness;
$www-theme-highlight: #5ccbcb;

// Arrow
.arrow {
    width: $shaft-width;
    transition: all 0.2s;

    &.reverse {
        .inner {
            margin-top: 0;
        }
    }

    .shaft {
        background-color: $www-theme-highlight;
        display: block;
        height: $shaft-thickness;
        position: relative;
        transition: all 0.2s;
        transition-delay: 0;
        will-change: transform;

        &::before,
        &::after {
            background-color: $www-theme-highlight;
            content: '';
            display: block;
            height: $arrow-head-thickness;
            position: absolute;
            top: 0;
            right: 0;
            transition: all 0.2s;
            transition-delay: 0;
        }

        &::before {
            transform-origin: top right;
        }

        &::after {
            transform-origin: bottom right;
        }
    }

    &.left {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;

        .shaft {
            width: 0;
            background-color: $www-theme-highlight;

            &::before,
            &::after {
                width: 0;
                background-color: $www-theme-highlight;
            }

            &::before {
                transform: rotate(0);
            }

            &::after {
                transform: rotate(0);
            }
        }
    }

    &.right {
        .shaft {
            width: $shaft-width;
            transition-delay: 0.2s;

            &::before,
            &::after {
                width: $arrow-head-width;
                transition: all 0.3s;
                transition-delay: 0.2s;
            }

            &::before {
                transform: rotate(40deg);
            }

            &::after {
                transform: rotate(-40deg);
            }
        }
    }
}

// Animated Arrow

.link {
    .inner {
        display: inline-block;
        position: relative;
        margin-top: 20px;
        transition: all 0.2s;
        font-size: 15px;
        text-transform: uppercase;

        @include font-semibold();

        &.reverse {
            transform: rotate(180deg);

            .main {
                .text {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .main {
        display: flex;
        align-items: center;
        transition: all 0.2s;

        .text {
            margin: 0 $text-arrow-space 0 0;
            line-height: 1;
        }

        .arrow {
            position: relative;
        }
    }

    &:hover {
        .arrow.left {
            .shaft {
                width: $shaft-width;
                transition-delay: 0.1s;

                &::before,
                &::after {
                    width: $arrow-head-width;
                    transition-delay: 0.1s;
                }

                &::before {
                    transform: rotate(40deg);
                }

                &::after {
                    transform: rotate(-40deg);
                }
            }
        }

        .main {
            transform: translateX($shaft-width + $text-arrow-space);
        }

        .arrow.right {
            .shaft {
                width: 0;
                transform: translateX(100%);
                transition-delay: 0;

                &::before,
                &::after {
                    width: 0;
                    transition: all 0.1s;
                    transition-delay: 0;
                }

                &::before {
                    transform: rotate(0);
                }

                &::after {
                    transform: rotate(0);
                }
            }
        }
    }
}
