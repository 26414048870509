@import '~shared/styles/config';

.patchNotes {
    padding: 90px 0 30px;

    .slider {
        background: rgba($www-dark, 0.4);
        backdrop-filter: blur(3px);
        padding: 30px 30px 10px;
        margin-top: 20px;
    }

    :global {
        .slick-track {
            display: flex;
            flex-grow: 1;
        }

        .slick-slide {
            pointer-events: none;

            .text {
                display: none;
            }
        }

        .slick-slide.slick-active {
            pointer-events: unset;
            z-index: 1;
        }
    }

    .inner {
        display: grid;
        grid-gap: 30px;

        @include viewport(mobile) {
            grid-template-columns: 3fr 4fr;
        }
    }

    .img {
        position: relative;

        img {
            width: 100%;
        }
    }

    .icon {
        position: absolute;
        top: 20px;
        left: 20px;
    }

    .heading {
        margin: 0;
        transition: $transition;
    }

    .text {
        position: relative;
        display: flex;
        flex-direction: column;
        clip-path: polygon(
            0 0,
            calc(100% - 128px) 0,
            calc(100% - 124px) 5px,
            calc(100% - 49px) 5px,
            calc(100% - 45px) 0,
            100% 0,
            100% 100%,
            0 100%
        );

        &:hover {
            .heading {
                color: $www-primary;
            }
        }

        ul {
            list-style: square;
            margin: 20px 0;
            padding: 0 0 0 20px;

            li {
                margin-top: 3px;
                font-weight: $font-regular;
                font-size: 18px;
                color: #c0c0c0;

                strong {
                    font-weight: $font-semibold;
                    color: #fff;
                }

                a {
                    color: $www-primary;
                    font-weight: 500;
                    transition: all 0.3s;

                    &:hover {
                        // border-bottom: 1px solid $www-theme-highlight;
                        filter: brightness(1.2);
                    }
                }
            }

            li::marker {
                color: $www-theme-highlight;
            }
        }
    }

    .tag {
        display: block;
        margin-bottom: 5px;
        color: $www-primary;
    }

    .link {
        margin-top: auto;

        @include arrow-hover;

        &::after {
            content: '';
            z-index: 1;

            @include fit;
        }
    }

    @include viewport(tablet) {
        padding: 170px 0 40px;
        min-height: 450px;
    }

    @include viewport(desktop) {
        .slider {
            margin-top: 40px;
        }

        .inner {
            align-items: start;
            grid-gap: 60px;
        }

        .text {
            height: 100%;
        }
    }
}
