@import '~shared/styles/config';

.formGroup {
    padding: 0;
    margin: 0 auto;
    position: relative;

    &.slim {
        min-height: 70px;
    }
}
