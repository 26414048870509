@import '~shared/styles/config';

.menu {
    visibility: hidden;
    opacity: 0;
    flex-direction: column;
    background-color: $www-dark;
    flex: 1 1 auto;
    z-index: 25;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    transform: translateY(-100%);
    transition: opacity 0.3s cubic-bezier(0.41, 0.28, 0.66, 0.97);
    padding-top: 70px;
    padding-bottom: 120px;
    overflow: auto;

    &.open {
        transform: translateY(0);
        opacity: 1;

        .search {
            transition: opacity ease-in-out 0.5s;
        }

        .navigation {
            transition: opacity ease-in-out 0.5s;
        }

        .footer {
            transition: opacity ease-in-out 0.3s 0.4s;
            // flex: 0 0 72px;
        }
    }

    &.closed {
        pointer-events: none;

        .search {
            transition: opacity ease-in-out 0.5s;
        }

        .navigation {
            transition: opacity ease-in-out 0.5s;
            flex: 1 1 auto;
        }

        .footer {
            transition: opacity ease-in-out 0s;
            flex: 1 1 auto;
        }
    }

    .search {
        padding: 10px 40px 0;
    }

    .navigation {
        @include font-medium();
        height: auto;
        text-transform: uppercase;
        font-size: 25px;
        letter-spacing: 0.15em;
        display: flex;
        flex-direction: column;
        padding: 40px 40px 20px;

        @include viewport(xsMax) {
            padding: 40px 20px 20px;
        }

        a:first-of-type {
            margin-top: 0;
        }
    }

    .footer {
        margin: 0 40px;
        margin-top: auto;
        padding-top: 20px;
        border-top: 1px solid rgba(#878787, 0.3);

        @include viewport(xsMax) {
            margin: 0 20px;
            margin-top: auto;
        }

        .social {
            flex: 0 0 72px;
            align-self: center;
            padding: 20px 0;
            // width: 83.75%; // 402/480
            position: fixed;
            bottom: 0;
            width: 100%;
            background: #13151a;

            .socialIcons {
                // To make it work in IE
                justify-content: space-between;
                justify-content: space-evenly;

                // To make it work in Edge
                // This is because of a bug in Edge
                // Edge thinks it supports space-evenly, but it doesn't
                // See: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/15947692/
                @supports (-ms-ime-align: auto) {
                    justify-content: space-between;
                }
            }
        }
    }

    @media only screen and (max-width: $max-1280) {
        visibility: visible;
        display: flex;

        .language {
            font-weight: $font-medium;
            letter-spacing: 0.05em;
        }
    }
}
