@import '~shared/styles/config';

// CTA button animation
@import 'CTA.scss';

.hero {
    padding: 90px 0 0;
    position: relative;
    background-color: #000;
    // overflow: hidden;

    // @include bg-img;

    // TODO: Remove below when we get new key art?
    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 168px;
    //     background-image: linear-gradient(
    //         to bottom,
    //         rgba(#000, 0.7),
    //         transparent
    //     );
    //     z-index: 1;
    // }

    @media only screen and (max-width: 768px) {
        padding: 300px 0 0;
    }

    @media only screen and (max-width: 508px) {
        padding: 200px 0 0;
    }

    .bgImage {
        width: auto;
        height: 100%;
        position: absolute;
        bottom: 0px;
        left: auto;
        top: auto;
        z-index: 5;
        display: flex;
        max-width: max-content;
        display: block;
        @include viewport(mobile) {
            display: block;
        }
        @include viewport(tablet) {
            display: none;
        }

        @include viewport(desktop) {
            display: none;
        }
    }

    .video {
        width: auto;
        height: 100%;
        position: absolute;
        bottom: 0px;
        left: auto;
        top: auto;
        z-index: 5;
        display: flex;
        max-width: max-content;
        display: none;

        @include viewport(mobile) {
            height: 100%;
            right: -3%;
        }

        @include viewport(tablet) {
            right: -10%;
            display: block;
        }

        @include viewport(desktop) {
            display: block;
            right: -12%;
            bottom: -5px;
        }

        @media only screen and (min-width: 1500px) {
            right: 5%;
        }

        @media only screen and (min-width: 2000px) {
            left: 42%;
        }

        @media only screen and (min-width: 2000px) {
            left: 42%;
        }
    }

    // Stars in bottom of hero area (use when image does not contain stars)
    // &::after {
    //     content: '';
    //     position: absolute;
    //     bottom: 0;
    //     width: 100%;
    //     height: 197px;
    //     background-image: url(//web.ccpgamescdn.com/aws/eveonline/images/transparency-transition.png);
    //     background-repeat: repeat-x;
    //     pointer-events: none;
    // }

    .container {
        position: relative;
        z-index: 555;
        display: flex;
        flex-direction: column;
        // flex-wrap: wrap;
        flex-grow: 1;
        // padding-bottom: 80px;
        min-height: 500px;

        @include viewport(mobile) {
            min-height: auto;
        }
    }

    h1,
    h2 {
        line-height: 1.1;
    }

    h1 {
        width: 100%;
        max-width: 700px;
    }

    h2 {
        margin-top: 10px;
        font-weight: $font-medium;
    }

    .buttons {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        justify-content: left;
        flex-wrap: wrap;
        row-gap: 20px;
        column-gap: 20px;

        a {
            width: 100%;
        }

        @include viewport(mobile) {
            flex-direction: row;

            a {
                width: auto;
            }
        }
    }

    .btn {
        margin-top: 10px;
        margin-right: 30px;
    }

    .ribbon {
        position: absolute;
        top: -22px;
        left: -30px;
        background: #d81f27;
        padding: 2px 12px;
        padding-right: 15px;
        color: white;
        text-align: center;
        text-transform: uppercase;
        font-size: 14px;
        max-width: 130px;
        line-height: 1.2;
        clip-path: polygon(
            100% 0,
            calc(100% - 8px) 50%,
            100% 100%,
            0 100%,
            0 0
        );

        @include font-semibold();
    }

    .announcement {
        position: absolute;
        bottom: -10px;
        right: 0;
        margin-top: 30px;
        opacity: 0;
        animation: hero-opacity 0.5s ease-in 2.5s forwards;

        @include viewport(mobileMax) {
            text-align: center;
        }

        .text {
            font-size: 1.1rem;
            text-transform: uppercase;
            font-weight: $font-semibold;
            letter-spacing: 0.05em;
            text-shadow: 0 0 6px rgb(0 0 0 / 75%);

            a {
                border-bottom: 1px solid $www-primary;
                padding-bottom: 3px;
                transition: color 0.2s;

                &:hover {
                    color: $www-primary;
                }
            }
        }
    }

    .quotesWrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .quotes {
            opacity: 0;
            animation: hero-opacity 0.5s ease-in 2.5s forwards;
        }
    }

    .returningPlayer {
        visibility: hidden;

        p {
            line-height: 1.2;
        }

        a {
            color: #fff;
            margin-left: 2px;

            &:hover {
                color: #00aeff;
            }
        }
    }

    &.colorTheme {
        h1 {
            color: $www-theme-h1;
        }

        h2 {
            color: $www-theme-h2;
        }
    }
}

/**********************************
    @Media queries
**********************************/

@include viewport(mobile) {
    .hero {
        padding: 250px 0 0;

        h2 {
            font-weight: $font-regular;
        }
    }
}

@include viewport(tablet) {
    .hero {
        padding: 220px 0 0;

        .buttons {
            margin-top: 30px;
        }

        .returningPlayer {
            text-shadow: unset;
        }

        // &.HeroCenter .container {
        //     margin-bottom: 100px;
        // }
    }
}

@include viewport(desktop) {
    .hero {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        justify-content: flex-end;

        @media screen and (min-height: 767px) {
            .container {
                margin-top: 120px;
            }
        }

        @media screen and (min-height: 1000px) {
            justify-content: center;
            min-height: 960px;

            .video {
                // height: auto;
            }

            // .container {
            //     margin-top: 310px;
            // }
        }

        /*******************************
        - Hero alignment starts
        *******************************/
        &.HeroRight {
            text-align: right;

            h1 {
                margin-left: auto;
                margin-right: -4px;
            }

            .btn {
                margin-right: 0;
                margin-left: 30px;
            }
        }

        &.HeroCenter {
            text-align: center;

            h1 {
                margin: 0 auto;
            }

            .btn {
                margin: 10px 15px 0;
            }
        }

        /*******************************
        - Hero alignment ends
        *******************************/

        .container {
            width: 100%;
        }
    }
}

@include viewport(xlargeDesktopUp) {
    .hero {
        padding: 220px 0 0;
    }
}

@keyframes hero-opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
