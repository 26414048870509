@import '~shared/styles/config';

.form-group {
    &:not(:first-of-type) {
        margin-top: 25px;
    }

    label {
        display: block;
        color: #fff;
        text-transform: uppercase;
        font-size: 14px;
        margin-bottom: 11px;
    }

    input,
    select {
        &.hasError {
            box-shadow: 0 1px 10px 2px tomato;
            outline: 2px solid tomato;
        }
    }

    span {
        display: none;

        &.hasError {
            display: block;
            color: tomato;

            @include font-light();

            font-size: 14px;
            margin-top: 5px;
        }
    }
}
