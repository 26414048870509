@import '~shared/styles/config';

.countdown {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;

    .countdown-title {
        margin: 0;
    }

    .column {
        p {
            font-weight: $font-semibold;
            text-transform: uppercase;
            font-size: 15px;
            margin-top: 10px;
            color: white;

            @include viewport(desktop) {
                color: #0E1958;
            }
        }
    }

    .timer {
        display: flex;
        justify-content: center;

        & > div {
            text-align: center;
            background: rgba(#0E1958, 0.85);
            padding: 5px 8px;
            margin: 7px;

            & > span {
                color: #fff;
                font-size: 28px;

                @include viewport(mobile) {
                    font-size: 48px;
                }
            }

            .unit {
                padding-top: 0;
                font-size: 14px;
                color: $www-silver-gray;
                text-transform: uppercase;
            }

            .time-unitsm {
                display: none;
            }
        }
    }
}
