@import '~shared/styles/config';

.videoEmbed {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    margin: 30px 0;

    & > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.audioEmbed {
    &::-webkit-media-controls-panel {
        background: $www-space-gray;
    }
}

// fix white background issue on iframe
.tweetEmbed iframe {
    color-scheme: auto;
}
