@import '~shared/styles/config';

.countdownContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 10px;

    background-color: #101010;

    @media (max-width: $screen-md) {
        justify-content: center;
    }
}

.title {
    font-weight: 600;
    font-size: 18px;
    font-family: Shentox;
    text-transform: uppercase;
    padding-top: 20px;
    line-height: 20px;
    color: #fff;
    margin-bottom: 15px;
    margin-left: 5px;
    margin-top: auto;
    text-align: center;

    @media (max-width: $screen-md) {
        text-align: center;
        padding-top: 30px;
    }
}

.timeUnit {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    margin: 0 5px;
    color: $www-primary;
    background-color: rgba(71, 71, 70, 0.2);
    min-width: 110px;

    @media (max-width: $screen-lg) {
        min-width: 100px;
    }

    @media (max-width: $screen-sm) {
        min-width: 90px;
    }

    @media (max-width: $screen-xs) {
        min-width: 75px;
    }
}

.number {
    font-size: 3rem;
    font-weight: 500;
    line-height: 100%;
    font-variant-numeric: tabular-nums;
}

.label {
    min-width: 0;
    font-size: 15px;
    color: #b0b0b0;
    font-weight: 600;
    text-transform: uppercase;
}

/* Responsive adjustments */
@media (max-width: 600px) {
    .countdown-container {
        flex-direction: column;
    }
    .time-unit {
        margin: 5px 0;
    }
}

.completed {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 1.5s, opacity 1.5s linear;
}
