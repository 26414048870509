@import '~shared/styles/config';

.logo {
    position: absolute;
    top: 0;
    left: 40px;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    margin-top: 56px;

    @include viewport(tablet) {
        left: 70px;
    }
}

.main {
    padding: 150px 0 200px 0;

    @include viewport(desktop) {
        padding: 250px 0 350px 0;
    }
}

.title {
    span {
        display: block;
    }
}

.text {
    @include viewport(tablet) {
        max-width: 50%;
    }
    margin-top: 20px;

    p {
        font-weight: 500;
        font-size: 22px;

        &.secondary {
            font-weight: 400;
        }
    }
}

.content {
    text-align: left;
    margin: 0 2vw;

    p {
        margin: 0;
        padding: 0;
    }
}

.dots {
    display: inline;
    margin-left: 5px;
}

.dot {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background-color: #fff; /* Customize the color as you like */
    // border-radius: 50%;
    display: inline-block;
    animation: bounce 1.4s ease-in-out both;
    animation-iteration-count: 5; /* Run the animation 5 times */
}

.dot1 {
    animation-delay: -0.32s;
}

.dot2 {
    animation-delay: -0.16s;
}

.dot3 {
    animation-delay: 0s;
}

@keyframes bounce {
    0%,
    80%,
    100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}
