@import '~shared/styles/config';

.twitch {
    // background: #000
    //     url(//web.ccpgamescdn.com/aws/eveonline/images/backgrounds/download.jpg)
    //     no-repeat top center;
    background-attachment: fixed;
    background-size: cover;
    padding-top: 120px;

    @media only screen and (min-width: $max-1280) {
        padding-top: 260px;
    }

    padding-bottom: 160px;
    position: relative;

    .heroBg {
        position: absolute;
        top: 0;
        right: -50px;
        display: none;

        @include viewport(tablet) {
            display: block;
            right: -100px;
        }

        @include viewport(desktop) {
            right: -150px;
            display: block;
        }

        @include viewport(xlargeDesktopUp) {
            right: -100px;
        }
    }

    .hero {
        padding: 0;

        .inner {
            max-width: 100%;
            // background-color: rgba(0, 0, 0, 0.6);
            background: rgba(21, 35, 41, 0.6);
            padding: 20px;

            @include viewport(tablet) {
                max-width: 50%;
                padding: 20px 40px;
                background: rgba(21, 35, 41, 0.6);
            }

            @include viewport(desktop) {
                background: transparent;
                padding: 0;
                max-width: 45%;
            }

            h2 {
                margin-bottom: 30px;
            }

            .heroTitle {
                margin-bottom: 20px;

                span {
                    font-size: 28px;

                    @include viewport(mobile) {
                        font-size: 32px;
                    }

                    @include viewport(tablet) {
                        font-size: 48px;
                    }
                }

                .twitchIcon {
                    margin-left: 10px;
                    font-size: 30px;
                    color: $social-tw;

                    @include viewport(tablet) {
                        font-size: 40px;
                    }
                }
            }

            .steps {
                margin: 40px 0;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                flex-direction: column;
                color: #fff;

                .loggedInUsername {
                    color: #b0b0b0;
                    margin-bottom: 20px;

                    span {
                        color: #fff;
                    }
                }
            }
        }
    }
}

.usernameis {
    p {
        font-size: 18px;
        font-weight: 400;
        margin: 0;
        padding: 0;
    }
}

.rewards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 60px;
    // margin-top: 40px;

    h3 {
        margin-bottom: 0;
    }

    .grid {
        margin-top: 20px;
        display: grid;
        grid-template-columns: repeat(1fr);

        @include viewport(tablet) {
            grid-template-columns: repeat(3, 1fr);
        }

        grid-gap: 30px;

        .item {
            background: rgba(21, 35, 41, 0.6);
            display: flex;
            flex-direction: column;
            position: relative;
            overflow: hidden;

            .rewardImage {
                overflow: hidden;
                position: relative;
            }

            .rewardText {
                padding: 20px;
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                h4 {
                    margin: 0;
                    font-size: 26px;
                    text-transform: uppercase;
                }

                p {
                    margin: 10px 0;
                    font-size: 18px;
                }
            }
        }
    }
}
