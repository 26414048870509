@import '~shared/styles/config';

.archive {
    padding: 100px 0;

    .heading {
        margin: 15px 0 40px;
    }

    .content {
        grid-gap: 50px;
        padding: 40px 15px;
    }

    .backLink {
        margin-bottom: 20px;
    }

    .pagination {
        display: grid;
        margin: 20px 0;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        margin-bottom: 30px;
    }

    .filter {
        color: $www-silver-gray;
    }

    .filter_title {
        font-size: 18px;
        text-transform: uppercase;
    }

    .date_group {
        display: flex;
        margin-top: 10px;
        overflow-x: auto;
    }

    .date {
        // flex-direction: column;
        text-transform: uppercase;
        font-size: 16px;
        margin-top: 2px;
        cursor: pointer;
        margin: 0 10px 0 0;

        &:hover,
        &.active {
            color: $www-primary;
        }
    }
}

@include viewport(mobile) {
    .archive {
        .grid {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@include viewport(tablet) {
    .archive {
        padding: 200px 0 0;

        .content {
            padding: 10px 15px;
            display: grid;
            grid-template-columns: 1fr minmax(min-content, 1290px) 1fr;
        }

        .pagination {
            margin: 0 0 30px;
        }

        .grid {
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 30px;
        }

        .filter {
            color: $www-silver-gray;
            text-align: right;
            margin-left: auto;
            margin-top: 50px;
        }

        .date_group {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
        }

        .date {
            font-size: 16px;
            margin: 2px 0 0;
        }
    }
}

@include viewport(tabletmax) {
    .archive {
        .overflow {
            position: relative;

            .date_group {
                background: rgba($www-night-black, 0.8);
                padding: 10px;
    
                &::after {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 100%;
                    width: 70px;
                    background-image: linear-gradient(to left, $www-night-black, transparent);
                    z-index: 1;
                    pointer-events: none;
                }
            }

            .date:last-child {
                padding-right: 50px;
            }
        }
    }
}