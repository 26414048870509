@import '~shared/styles/config';
@media only screen and (max-width: 600px) {
    .content {
        padding: 35px !important;
    }
    .heading {
        font-size: 26px !important;
    }
}
.downloadText {
    padding-top: 35px;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 18px;
    transition: 0.2s ease-in-out;
}
.downloadText a {
    transition: 0.2s ease-in-out;
}
.downloadText a:hover {
    color: #5ccbcb;
}

.launch {
    padding: 150px 0 80px;
    background-position: 50% 50%;
    height: 90vh;

    .content {
        text-align: center;
        max-width: 840px;
        margin: 0 auto;
        padding: 54px 135px 10px 135px;
        background-color: #000000cc;
        backdrop-filter: blur(3px);
        clip-path: none !important;

        p {
            font-weight: lighter;
        }
    }

    .heading {
        margin-top: 0;
        margin-bottom: 16px;
        font-size: 36px;
        max-width: 430px;
        margin: 0 auto;
    }

    .buttons {
        grid-gap: 20px;
        padding-top: 10px;
    }
}

@include viewport(tablet) {
    .launch {
        padding: 200px 0;

        .content {
            padding: 40px 60px;
        }
    }
}
