@import '~shared/styles/config';

.TagsContainer {
    display: flex;
    position: relative;
    margin-bottom: 20px;
    padding: 0 20px;
    background-color: rgba(33, 33, 33, 0.8);
    width: 100%;
    align-items: center;
    min-height: 60px;

    ul,
    li {
        @include reset-list;
    }

    .search {
        position: relative;

        @include viewport(desktop) {
            min-width: 260px;
        }

        form,
        input {
            width: 100%;
        }

        input {
            @include reset-button;

            color: white;
            border: none;
            padding: 5px 10px 5px 15px;
            background: $www-space-gray;
            border-radius: 24px;
            padding-right: 30px;
            border: 1px solid transparent;
            font-size: 15px;
            transition: background 0.3s ease;

            &:focus {
                background: #353e42;
            }
        }

        svg {
            color: $www-silver-gray;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }

        button {
            @include reset-button;

            &:hover {
                cursor: pointer;

                svg {
                    color: #fff;
                }
            }
        }
    }
}

/**********************************
    @Media queries
**********************************/

@include viewport(tabletmax) {
    .TagsContainer {
        flex-direction: column-reverse;
        padding: 10px;
        margin-bottom: 0;

        .search {
            width: 100%;
        }
    }
}
