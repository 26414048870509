@import '~shared/styles/config';

.hero {
    display: flex;
    align-items: center;
    padding: 120px 0 30px;
    min-height: 300px;
    position: relative;
    background-color: rgba(#000, 0.1);
    background-blend-mode: overlay;

    h1,
    h2 {
        line-height: 1.2;
    }

    h1 {
        @include viewport(mobile) {
            // not ideal but removes extra space on side
            margin-left: -3px;
        }
    }

    h2 {
        margin-top: 8px;
        margin-bottom: 30px;

        @include viewport(mobile) {
            max-width: 45%;
            min-height: 100px;
        }
    }

    &::before {
        @include viewport(desktop) {
            content: '';
            position: absolute;
            bottom: -3px;
            width: 100%;
            height: 197px;
            background-image: url(//web.ccpgamescdn.com/aws/eveonline/images/transparency-transition.png);
            background-repeat: repeat-x;
            pointer-events: none;
        }
    }

    .content {
        margin-top: 10px;
        flex: 1;

        .text {
            margin-bottom: 20px;

            @include viewport(desktop) {
                margin-bottom: 60px;
                min-height: 200px;
            }
        }
    }

    .buttonContainer {
        display: grid;
        grid-gap: 20px;
    }

    @include viewport(desktop) {
        min-height: 350px;

        .content {
            margin-top: 160px;
        }

        .buttonContainer {
            grid-template-columns: max-content max-content;
        }
    }

    @include viewport(tablet) {
        // padding: 100px 0;
        min-height: 450px;
    }

    @include viewport(desktop) {
        min-height: 600px;
    }
}
