@import '~shared/styles/config';

.wrapper {
    outline: 1px solid white;
}

.content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0;
    margin: 0 auto;
    background-color: rgba(16, 16, 16, 0.4);
    backdrop-filter: blur(4px);

    img {
        max-width: 100%; /* Ensures the image doesn't overflow its container */
        height: auto; /* Keeps the aspect ratio */
        flex-shrink: 0; /* Prevents the image from shrinking too much */
        width: 120px;

        @include viewport(xs) {
            width: 150px;
            // outline: 1px solid orange;
        }

        @include viewport(mobile) {
            width: 180px;
            // outline: 1px solid blue;
        }

        @include viewport(tablet) {
            width: 180px;
        }

        @include viewport(desktop) {
            width: 280px;
            // outline: 2px solid white;
        }
    }
}

.copy {
    padding-left: 25px;
    position: relative;

    .headline {
        font-size: 14px;
        margin: 0;
        line-height: normal;

        @include viewport(mobile) {
            font-size: 16px;
            margin: 0;
        }

        font-weight: 500;
        padding: 0;
        color: rgb(192, 192, 192);
    }

    .name {
        font-size: 14px;
        margin: 0;
        line-height: normal;

        @include viewport(xs) {
            font-size: 18px;
            margin: 0;
        }

        @include viewport(desktop) {
            font-size: 20px;
            margin: 0;
        }
        font-weight: 500;
        padding: 0;
        color: #fff;
    }

    .link {
        color: $www-theme-highlight;
        text-decoration: none;
        font-size: 16px;
        font-weight: 500;
        margin: 5px 0 0 0;
        padding: 0;
        display: none;
        transition: filter 0.2s ease-in-out;

        @include viewport(mobile) {
            display: block;
        }

        &:hover {
            filter: brightness(1.2);
        }
    }

    .externalLink {
        margin-left: 5px;
        color: $www-theme-highlight;

        @include viewport(mobile) {
            display: none;
        }
    }
}

.retailer {
    display: flex;
    align-items: center;
}
