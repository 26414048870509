@import '~shared/styles/config';

.newsByTagContainer {
    padding: 100px 0;

    .heading {
        margin: 15px 0 40px;
    }

    .pagination {
        display: grid;
        grid-template-columns: 1fr;
        margin-top: 40px;
        grid-gap: 30px;
    }

    .grid {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 20px;
        margin-top: 40px;
    }
}

@include viewport(mobile) {
    .newsByTagContainer {
        .grid {
            grid-template-columns: 1fr 1fr;
        }
    }
}

@include viewport(tablet) {
    .newsByTagContainer {
        padding: 200px 0 50px 0;

        .grid {
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 30px;
        }
    }
}
