@import '~shared/styles/config';

.videoWrapper {
    .close {
        position: absolute;
        top: -45px;
        right: 0;
        z-index: 1;
        opacity: 0.7;
        transition: 0.2s ease-in-out;
        font-size: 36px;
        cursor: pointer;
        transform: rotate(90deg);
        color: #fff;

        &:hover {
            opacity: 1;
        }
    }

    .player {
        @include fit;
    }

    h5 {
        @include font-medium();
        font-size: 24px;
        text-transform: uppercase;
        text-align: left;
        letter-spacing: 1.5px;
        margin-bottom: 30px;
    }

    p {
        font-size: 18px;
        margin-top: 30px;
        text-align: left;
    }

    .btnWrapper {
        display: flex;
        align-items: center;
        width: fit-content;
        // margin-top: 10px;

        button {
            clip-path: none;
        }

        svg {
            cursor: pointer;
            width: 32px;

            path {
                fill: #fff;
                transition: fill 0.3s ease-in-out;

                &:first-child {
                    fill: $www-academy;
                }
            }
        }

        &:hover {
            svg {
                path {
                    fill: #fff;

                    &:first-child {
                        fill: $www-academy;
                    }
                }
            }
        }

        a {
            clip-path: none;
            padding-left: 30px;
            padding-right: 40px;
        }

        svg {
            margin-left: 18px;
            z-index: 3;
        }
    }

    .btn {
        padding-left: 20px;
        padding-right: 33px;
        height: 46px;

        span {
            display: flex;
            align-items: center;
        }

        .btn_icon {
            margin-right: 10px;
        }
    }

    .wrapper {
        overflow: hidden;
        position: relative;
        margin-top: 20px;
        outline: 1px solid rgba(140, 140, 140, 0.21);

        &:hover {
            img {
                filter: brightness(1.2);
            }

            .icon {
                transform: translate(-50%, -50%) scale(1.1);
            }
        }

        img {
            width: 100%;
            cursor: pointer;
            position: relative;
            transition: 0.2s ease-in-out;
        }

        .icon {
            @include centerer;

            transition: 0.2s ease-in-out;
            cursor: pointer;
        }
    }
}
