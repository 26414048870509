@import '~shared/styles/config';

.link {
    margin-top: 15px;
    cursor: pointer;

    @include arrow-hover;

    &::after {
        content: '';
        z-index: 1;
    }
}

.container {
    position: relative;
    z-index: 1000;

    display: grid;
    grid-template-columns: 1fr;

    grid-column-gap: 70px;

    .img {
        margin-top: 20px;
    }

    // These are square bullets, do we have the same code somewhere else?
    ul {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;

        li {
            margin-bottom: 10px;
        }
    }

    ul {
        list-style-type: none;
        padding-left: 0;
    }

    ul li {
        position: relative;
        padding-left: 20px;
    }

    ul li::before {
        content: '';
        width: 5px;
        height: 5px;
        background-color: var(--bullet-color);
        position: absolute;
        left: 0;
        top: 12px;
    }

    @include viewport(mobile) {
        grid-template-columns: 1fr 1fr;

        .img {
            margin-top: 80px;
            @include box('default', 1, 0, 0, 0);
        }
    }
}
