.unlinkButton {
    background: #101010;
    border-radius: 12px;
    display: inline-flex;
    justify-content: center;
    color: #b0b0b0;
    font-size: 16px;
    width: 100%;
    min-height: 36px;
    margin-top: 20px;
    padding-top: 6px;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
        border: 1px solid #5a5a5a;
    }
}

.confirmation {
    margin-left: 20px;
    .air {
        display: inline;
        margin-left: 20px;
    }
}
