@import '~shared/styles/config';

.border {
    width: 100%;
    height: 2px;
    position: relative;
    margin-bottom: 58px;
    margin-top: 24px;

    &.reversed {
        margin-bottom: 24px;
        margin-top: 58px;
    }

    .inner {
        position: absolute;
        top: -1px;
        width: 84px;
        height: 12px;
        margin-left: 30px;
        transform: perspective(100px) rotateX(-60deg);
        background: inherit;

        &.reversed {
            top: unset;
            bottom: -1px;
            transform: perspective(100px) rotateX(60deg);
        }

        &.centered {
            left: 0;
            right: 0;
            margin: 0 auto;
        }
    }
}

.primary {
    background: $brand-higlight;
}

.secondary {
    background: $brand-yellow;
}

.dark {
    background: $brand-dark-lighter;
}


.transparent {
    background: rgba(255, 255, 255, 0.2);
}


.small {
    height: 1px;

    .inner {
        top: -2px;
    }
}

.clean {
    width: 100%;

    .inner {
        display: none;
    }
}

@include viewport (mobileMax) {
    .border {
        margin-bottom: 28px;
        margin-top: 16px;
    }

    .small {
        width: 100%;
    }
}
