@import '~shared/styles/config';

.vanguard {
    padding: 80px 0 60px;

    .inner {
        min-height: 760px;
        display: grid;
        grid-template-columns: 1fr;
        background-size: cover;
        grid-auto-flow: column-reverse;
        position: relative;

        @include viewport(tablet) {
            grid-template-columns: 1fr 1fr;
        }

        .badgeContainer {
            position: absolute;
            top: 30px;
            right: 30px;
            overflow: hidden;
            display: none;

            @include viewport(tablet) {
                display: block;
            }

            .badge {
                background-color: #ff4d4d;
                font-weight: 600;
                font-size: 18px;
                border-radius: 4px;
                padding: 0px 10px;
                color: white;
                text-transform: uppercase;
            }
        }

        .content {
            // text-align: left;

            order: 2;

            @include viewport(tablet) {
                text-align: left;
                order: 2;
                padding-right: 110px;
            }

            .copy {
                margin-bottom: 30px;

                h2 {
                    i {
                        color: red;
                    }
                }

                .foundersAccess {
                    font-size: 15px;
                    font-weight: 600;
                    margin-bottom: 20px;
                }
            }

            .button {
                margin-top: 20px;
                text-align: center;
            }

            .buttonSteam {
                margin-right: 20px;
                margin-top: 20px;
                text-align: center;

                .steamIcon {
                    margin-right: 10px;
                    height: 1.3em;
                    vertical-align: -0.25em;
                }
            }

            // .steamLink {
            //     display: flex;
            //     font-size: 18px;
            //     font-weight: $font-semibold;
            //     margin-top: 20px;
            //     text-transform: uppercase;

            //     .icon {
            //         display: flex;
            //         align-items: center;
            //         justify-content: center;

            //         svg {
            //             margin-right: 10px;
            //         }
            //     }
            // }
        }
        .image {
            // margin-bottom: 60px;
            display: flex;
            justify-content: center;
            order: 1;

            svg {
                width: 40vw;
                height: auto;
            }

            @include viewport(tablet) {
                order: 2;

                svg {
                    width: 100%;
                    max-width: 100%;
                }
            }
        }

        align-items: center;
        justify-items: center;
    }

    @include viewport(mobile) {
        padding: 100px 0 100px;
    }

    @include viewport(tablet) {
        padding: 150px 0 50px;
    }
}
