@import '~shared/styles/config';

.bg {
    padding-top: 120px;
    padding-bottom: 80px;

    @include viewport(tablet) {
        padding-top: 240px;
        padding-bottom: 120px;
    }
}

.copy {
    margin-bottom: 40px;

    @include viewport(tablet) {
        max-width: 60%;
        margin-bottom: 100px;
    }
}

.heading {
    margin-bottom: 40px;
}
