@import '~shared/styles/config';

.outer {
    overflow: hidden;
    display: block;

    .inner {
        transition: 0.8s transform, 0.4s opacity;
        transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
        transition-delay: 0.2s;
        transform: translateY(105%) translateZ(0);
        display: inline-block;
        opacity: 0;

        &.visible {
            transform: translateZ(0);
            opacity: 1;
        }

        &.fw {
            @include font-light();
        }
    }

    $maxElements: 6;

    @for $i from 1 to $maxElements {
        &:nth-of-type(#{$i + 1}) .inner {
            // background: rgb($i, $i, $i);
            transition-delay: $i * 0.2s + 0.1s;
        }
    }
}
