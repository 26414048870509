@import '~shared/styles/config';

.loading {
    @include fit;

    position: fixed;
    transition: all 400ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    background: $brand-dark;
    z-index: 9999;
    transition-delay: 0.7s;
    display: flex;
    align-items: center;
    justify-content: center;

    .ship {
        text-align: center;
        animation: fly 3s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
    }

    .boosters {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        justify-items: center;
        grid-gap: 20px 0;
        overflow: hidden;
        position: relative;
        margin: 0 16px;

        &::after {
            content: '';
            height: 80%;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background: linear-gradient(0deg, rgba($brand-dark, 1), transparent);
        }
    }

    .booster {
        position: relative;
        width: 4px;
        height: 80px;
        background: $www-primary;
        background: rgba($www-primary, 0.75);
        box-shadow: 0 0 5px $www-primary;
        animation: boosters 0.75s infinite linear;

        &:nth-child(2) {
            height: 50px;
            animation: boostersMiddle 0.75s infinite linear 0.1s;
            margin-top: 8px;
        }
    }
}

@keyframes fly {
    0%,
    100% {
        transform: translateY(10px);
    }

    40% {
        transform: translateY(0);
    }
}

@keyframes boosters {
    0%,
    100% {
        height: 80px;
    }

    20% {
        height: 60px;
    }
}

@keyframes boostersMiddle {
    0%,
    100% {
        height: 50px;
    }

    20% {
        height: 35px;
    }
}

/* stylelint-disable-next-line */
:global {
    .hasLoaded {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
}
