@import '~shared/styles/config';

.bottomCTA {
    padding: 100px 0 200px;
    background: url('//web.ccpgamescdn.com/aws/eveonline/images/return/bottom-planet.png') no-repeat top center;
    background-size: cover;
    text-align: center;
    margin: 0 auto;

    @include viewport(tabletmin) {
        min-height: 370px;
        margin-top: 60px;
    }

    h3 {
        text-transform: uppercase;
        font-size: 28px;

        @include viewport(tabletmin) {
            font-size: 38px;
        }

        @include font-medium();
    }

    .content {
        display: flex;
        min-height: 150px;
        justify-content: center;
        // margin-top: 60px;
        align-items: center;
        margin: 40px 20px;

        @include viewport(tabletmax) {
            flex-direction: column;
        }

        // a {
        //     margin-top: 20px;

        //     @include viewport(tabletmin) {
        //         &:first-child {
        //             margin-right: 30px;
        //         }
        //     }
        // }

        // div {
        //     flex-direction: column;
        //     margin: 0;

        //     a {
        //         padding: 0 60px;
        //         font-size: 20px;
        //         @include font-semibold();
        //     }

        //     @include viewport(tabletmax) {
        //         margin: 10px 20px;
        //     }
        // }
    }
}
