@import '~shared/styles/config';

.blockQuote {
    margin: 0;
    padding: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    display: block;
    padding-left: 28px;
    position: relative;

    &:after {
        content: "";
        background: #5CCBCB;
        position: absolute;
        top: 9px;
        left: 1px;
        bottom: 8px;
        width: 1px;
    }

    p {
        margin-left: 30px;
        max-width: 85%;
        font-size: 26px;
        line-height: 155%;
        margin: 0;
    }

    i {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        color: #C0C0C0;
        line-height: 155%;
        padding-top: 10px;
        position: relative;
        display: block;
    }
}
