@import '~shared/styles/config';

.container {
    position: relative;
    height: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;

    .universe {
        background: #000;
        color: #f8f8ff;
        padding: 0;
        overflow: hidden;
        width: 100vw;
        display: none;

        @include viewport(tablet) {
            display: inline-block;
        }

        .underlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 2;

            &.bgOne {
                // animation: scale-underlay-one 10s infinite;
                animation: scale-underlay-bg-one 60s infinite;
                animation-timing-function: linear;
            }

            &.bgTwo {
                animation: scale-underlay-bg-two 60s infinite;
                animation-timing-function: linear;
            }

            &.one {
                animation: scale-bg-one 45s infinite;
                animation-timing-function: linear;
            }

            &.two {
                animation: scale-bg-two 45s infinite;
                animation-timing-function: linear;
            }
        }
    }

    .children {
        position: relative;
        z-index: 3;
    }
}

// from ccpgames.com

@keyframes scale-underlay-bg-one {
    0% {
        transform: scale3d(1, 1, 1);
        opacity: 0;
    }

    56%,
    6% {
        opacity: 1;
    }

    100%,
    60% {
        transform: scale3d(1.3, 1.3, 1.3);
        opacity: 0;
    }
}

@keyframes scale-underlay-bg-two {
    0%,
    100% {
        transform: scale3d(1.3, 1.3, 1.3);
        opacity: 1;
    }

    4%,
    54% {
        opacity: 1;
    }

    10% {
        transform: scale3d(1.35, 1.35, 1.35);
        opacity: 0;
    }

    50% {
        transform: scale3d(1, 1, 1);
        opacity: 0;
    }
}

@keyframes scale-bg-one {
    0% {
        transform: scale3d(1, 1, 1);
        opacity: 0;
    }

    10%,
    50% {
        opacity: 1;
    }

    100%,
    60% {
        transform: scale3d(1.5, 1.5, 1.5);
        opacity: 0;
    }
}

@keyframes scale-bg-two {
    0%,
    100% {
        transform: scale3d(1.5, 1.5, 1.5);
        opacity: 1;
    }

    10% {
        transform: scale3d(1.55, 1.55, 1.55);
        opacity: 0;
    }

    50% {
        transform: scale3d(1, 1, 1);
        opacity: 0;
    }

    60% {
        opacity: 1;
    }
}
