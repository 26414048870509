@import '~shared/styles/config';

.announcement {
    justify-content: flex-start;
    position: relative;
    bottom: 0;
    z-index: 55;
    right: 0;
    margin-bottom: -55px;
    margin-top: auto;
    opacity: 0;
    animation: fadeIn 1s ease-in-out forwards;
    animation-delay: 2s;
    // display: none;
    display: flex;
    flex-direction: column;

    @include viewport(xs) {
        // flex-direction: row;
        flex-direction: initial;
        justify-content: flex-start;
        margin-right: 20px;
        margin-top: 30px;
    }

    @media only screen and (max-width: 508px) {
        margin-top: 30px;
    }

    @include viewport(tablet) {
        position: absolute;
        bottom: -55px;
        margin-top: 0;
        margin-bottom: 0;
    }

    @media only screen and (min-width: $min-1280) {
        margin-right: 40px;
    }

    .saleWrapper {
        display: grid;
        grid-template-columns: 1fr auto;

        img {
            transition: 0.2s ease-in-out;
            height: 100%;
            object-fit: cover;
        }

        &:hover {
            img {
                filter: brightness(1.2);
            }

            .button span {
                // margin-left: 16px;
                transform: translateX(8px);
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            padding: 10px 15px;
            background: #fff;
            text-transform: uppercase;
            color: #101010;
            font-size: 15px;
            font-weight: 600;

            @include viewport(mobile) {
                padding: 14px 20px;
            }

            .headline {
                color: rgb(63, 63, 63);
            }

            .teaser {
                color: #101010;
                font-size: 18px;
                margin-bottom: 5px;
            }

            .button {
                margin-top: auto;

                span {
                    transition: 0.2s ease-in-out;
                }
            }
        }
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.countDownWrapper {
    display: grid;
    flex-direction: column;
    justify-content: center;
}

.buttonWrapper {
    margin-top: 15px;
    justify-self: right;
    padding-right: 15px;

    font-size: 15px;
    font-weight: 600;
}

.announcement.countDownAnnouncementWrapper {
    justify-content: center;
    margin-bottom: -100px;

    cursor: pointer;

    @include arrow-hover;

    @include viewport(tablet) {
        margin-bottom: 0px;
        bottom: -115px;
    }
}
