@import '~shared/styles/config';

/*********************************************************************
Customized react-select-search styles
https://github.com/tbleckert/react-select-search/blob/main/style.css
*********************************************************************/

@mixin select-option {
    font-size: 15px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-weight: $font-medium;
    color: #fff;
}

/**
 * Main wrapper
 */
.container {
    width: 100%;
    position: relative;
    box-sizing: border-box;

    /**
    * Value wrapper
    */
    .value {
        position: relative;
        z-index: 1;
    }

    .value::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: calc(50% - 9px);
        right: 19px;
        width: 11px;
        height: 11px;
    }

    /**
    * Input
    */
    .input {
        display: block;
        height: 36px;
        width: 100%;
        padding: 0 40px 0 16px;
        background: $www-space-gray;
        border: 1px solid transparent;
        outline: none;
        text-align: left;
        text-overflow: ellipsis;
        line-height: 36px;
        min-height: 60px;
        -webkit-appearance: none;

        @include select-option;

        &:focus {
            background: $www-space-gray;
        }
    }

    .input::-webkit-search-decoration,
    .input::-webkit-search-cancel-button,
    .input::-webkit-search-results-button,
    .input::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    .input:not([readonly]):focus {
        cursor: initial;
    }

    /**
    * Options wrapper
    */
    .select {
        background: $www-space-gray;

        ul {
            @include reset-list;
        }

        button {
            @include select-option;
        }
    }

    /**
    * Options
    */
    .options {
        list-style: none;
    }

    /**
    * Option row
    */
    .row:not(:first-child) {
        border-top: 1px solid $www-night-black;
    }

    /**
    * Option
    */
    .option,
    .not-found {
        display: block;
        height: 36px;
        width: 100%;
        padding: 0 16px;
        background: $www-space-gray;
        border: none;
        outline: none;
        // font-family: 'Noto Sans', sans-serif;
        font-size: 14px;
        text-align: left;
        cursor: pointer;
        transition: background $transition;
    }
}

.container *,
.container *::after,
.container *::before {
    box-sizing: inherit;
}

.container--multiple .option {
    height: 48px;
}

.option.is-selected {
    background: $www-primary;
    color: #fff;
}

.option.is-highlighted,
.option:not(.is-selected):hover {
    background: lighten($www-space-gray, 10%);
}

.option.is-highlighted.is-selected,
.option.is-selected:hover {
    background: $www-primary;
    color: #fff;
}

/**
 * Group
 */
.group-header {
    font-size: 10px;
    text-transform: uppercase;
    background: #eee;
    padding: 8px 16px;
}

/**
 * States
 */
.container.is-disabled {
    opacity: 0.5;
}

.container.is-loading .value::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
    background-size: 11px;
}

.container:not(.is-disabled) .input {
    cursor: pointer;
}

/**
 * Modifiers
 */
.container--multiple {
    overflow: hidden;
}

.container:not(.is-loading):not(.container--multiple) .value::after {
    transform: rotate(45deg);
    border-right: 1px solid $www-silver-gray;
    border-bottom: 1px solid $www-silver-gray;
    pointer-events: none;
}

.container--multiple .input {
    cursor: initial;
}

.container--multiple:not(.container--search) .input {
    cursor: default;
}

.container:not(.container--multiple) .input:hover {
    border-color: $www-primary;
}

.container:not(.container--multiple) .select {
    position: absolute;
    z-index: 2;
    top: 70px;
    right: 0;
    left: 0;
    overflow: auto;
    max-height: 360px;
}

.container--multiple .select {
    position: relative;
    overflow: auto;
    max-height: 260px;
    border-top: 1px solid #eee;
}

.not-found {
    height: auto;
    padding: 16px;
    text-align: center;
    color: #888;
}
