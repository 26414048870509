@import '~shared/styles/config';

.navitem {
    // color: #ba1f7e;
    // background-color: white;

    @include reset-list;
    @include font-medium();

    position: relative;
    // clip-path: polygon(0 0, 30px 0, 34px 5px, 114px 5px, 118px 0, 100% 0, 100% 100%, 0 100%);
    font-size: 22px;
    text-transform: uppercase;
    flex-direction: row;
    color: #212121;
    margin-bottom: 30px;
    // background-color: $www-smoke-gray;
    // filter: drop-shadow(0 0 0.75rem black);

    .wrap {
        filter: drop-shadow(0 0 1rem black);
    }

    .clipped {
        background-color: $www-academy-secondary;
        clip-path: polygon(
            0 0,
            30px 0,
            34px 5px,
            114px 5px,
            118px 0,
            100% 0,
            100% 100%,
            0 100%
        );

        color: #fff;
        // @include button-hover(#ddd);
    }

    // hover effect not applied on current/active element
    &:not(&.current):hover {
        .clipped::before {
            transform: translate3d(0, 0, 0);
            opacity: 1;
        }

        &:not(.article) {
            .icon path {
                fill: $www-academy;
            }
        }

        .arrow {
            transform: translateX(5px);
        }
    }

    @include viewport(mobileMax) {
        margin-bottom: 2px;

        .wrap {
            filter: none;
        }

        .clipped {
            clip-path: none;
        }
    }

    &.current {
        // color: white;
        // background-color: #ba1f7e;

        .clipped {
            color: $www-academy-secondary;
            background-color: #fff;

            .arrow {
                svg path {
                    fill: #212121;
                }
            }
        }
    }

    a {
        position: relative;
        padding: 34px 20px;
        display: flex;
        align-items: center;
        justify-items: center;
        width: 100%;
        height: 100%;

        @include viewport(tablet) {
            max-height: 100px;
        }

        @include viewport(desktopMax) {
            padding: 25px 15px;
        }

        @include viewport(mobileMax) {
            padding: 5px 20px 5px 0px;
        }

        .icon {
            display: inline-flex; // adjusts alignment

            @include viewport(mobileMax) {
                display: none;
            }

            svg path {
                transition: 0.2s ease-in-out;
            }
        }

        .text {
            flex-grow: 4;
            text-align: left;
            padding-left: 15px;

            @include viewport(desktopMax) {
                font-size: 18px;
            }
        }

        .arrow {
            padding: 0 0 0 20px;
            transition: 0.25s ease-in-out;

            @include viewport(mobileMax) {
                // display: none;
            }
        }
    }

    &.article {
        color: #fff;
        line-height: 1.2;

        .clipped {
            background-color: $www-academy;

            @include button-hover(darken($www-academy, 5%));
        }

        .arrow {
            padding: 0 0 0 10px;

            path {
                fill: #fff;
            }
        }

        @include viewport(mobile) {
            .text {
                padding-left: 0;
                font-size: 18px;
            }

            .icon {
                z-index: -1;
                opacity: 0.2;

                @include centerer;

                svg {
                    width: 60px;
                    height: 60px;
                }

                path {
                    fill: darken($www-academy, 17%);
                }
            }

            &:hover {
                .icon path {
                    // fill: lighten($www-academy, 15%);
                }
            }
        }
    }
}

.frame {
    background-color: transparent;
}
