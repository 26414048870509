@import '~shared/styles/config';

.plexBalance {
    display: flex;
    align-items: center;

    .icon {
        margin-right: 10px;
    }

    .text {
        font-weight: $font-semibold;
        font-size: 14px;
        vertical-align: middle;
        line-height: 1;
    }
}
