@import '~shared/styles/config';

.tags {
    position: absolute;
    z-index: 1;
    top: 10px;
    right: 0;
    margin: 0;
    padding: 0;
    white-space: nowrap;

    li {
        margin: 0;
        list-style-type: none;
        padding: 1px 5px 1px 10px;
        background: #fff;
        color: $www-primary;
        border-right: 5px solid $www-primary;
        margin-top: 1px;
        overflow: hidden;
        border-radius: 28px 0 0 28px;
        font-size: 14px;
        text-transform: lowercase;
        max-width: 28px;

        span {
            color: #fff;
        }
    }

    &:hover {
        max-width: 500px;

        li {
            max-width: 500px;
            width: auto;
            transition: max-width 0.5s ease;

            span {
                color: inherit;
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }
}
