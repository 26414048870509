.visibility {
    // transition: 0.2s ease-in-out;
    transition: 0.8s transform, 0.4s opacity;
    transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: transform, opacity;
    width: 100%;
    height: 100%;
}

.inVisible {
    opacity: 0;
}

.fadeUp {
    transform: translate3d(0, 30px, 0);

    &.isVisible {
        transform: translate3d(0, 0, 0);
    }
}

.fadeDown {
    transform: translate3d(0, -30px, 0);

    &.isVisible {
        transform: translate3d(0, 0, 0);
    }
}

.fadeRight {
    transform: translate3d(-30px, 0, 0);

    &.isVisible {
        transform: translate3d(0, 0, 0);
    }
}

.fadeLeft {
    transform: translate3d(30px, 0, 0);

    &.isVisible {
        transform: translate3d(0, 0, 0);
    }
}

.isVisible {
    opacity: 1;
}

// .delay {
//     transition-delay: 0.25s;
// }
