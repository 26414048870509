@import '~shared/styles/config';

$bar-height: 50px;

.bar {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1;
    background: $www-blood-red;
    height: $bar-height;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    padding: 0 10px;
    padding-right: 60px;
    line-height: 1.2;

    p {
        font-size: inherit;
        margin: 0;
    }

    .icon {
        margin-right: 15px;
    }

    .close {
        position: absolute;
        top: 17px;
        right: 20px;
        z-index: 1;
        cursor: pointer;
        outline: none;
    }
}

@include viewport(mobileMax) {
    .bar {
        white-space: nowrap;
        overflow-x: auto;
        justify-content: flex-start;

        &::after {
            content: '';
            position: fixed;
            background-image: linear-gradient(to left, $www-blood-red, transparent);
            width: 70px;
            height: inherit;
            right: 0;
            top: 0;
            pointer-events: none;
        }

        &::-webkit-scrollbar {
            display: none;
        }

        p {
            padding-right: $bar-height;
        }

        .close {
            position: fixed;
            right: 10px;
        }
    }
}