@import '~shared/styles/config';

.cards {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-top: 50px;

    @include viewport(tablet) {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 5px;
    }

    .card {
        cursor: pointer;
        position: relative;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-column-gap: 30px;
        grid-row-gap: 10px;
        padding: 20px 10px;

        @include viewport(mobile) {
            grid-row-gap: 5px;
        }

        @include viewport(desktop) {
            padding: 40px 20px;
        }

        flex-direction: row;
        background-color: #212121;
        clip-path: polygon(
            0 0,
            45px 0,
            49px 5px,
            104px 5px,
            108px 0,
            113% 0,
            100% 120%,
            0 100%
        );

        .img {
            display: flex;
            align-items: center;
            grid-row: 2 span;

            @include viewport(mobile) {
                grid-row: 2 span;
            }

            svg {
                max-width: 70px;
                max-height: 70px;

                @include viewport(mobile) {
                    max-width: 100px;
                    max-height: 100px;
                }

                @include viewport(tablet) {
                    max-width: 120px;
                    max-height: 120px;
                }

                @include viewport(desktop) {
                    max-width: initial;
                    max-height: initial;
                }
            }
        }

        h3 {
            margin: 0;
            padding: 0;
            color: $www-omega;
        }

        p {
            margin: 0;
            font-size: 20px;

            @include viewport(tablet) {
                font-size: 20px;
            }
        }

        a {
            &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }
        }

        .btn {
            margin-top: 20px;
            height: 32px;
            grid-column: 1 / span 2;

            @include viewport(tablet) {
                width: fit-content;
                grid-column: 2 / 2 span;
                margin: 10px 0 0 0;
            }

            span {
                color: #000;
            }
        }

        transition: 0.2s;

        &:hover {
            background-color: #101010;
        }
    }
}
