@import '~shared/styles/config';

.item {
    position: relative;
    cursor: pointer;
    text-transform: none;
    letter-spacing: 0.01em;
    color: #fff;
    font-size: 18px;
    margin-left: 22px;
    margin-bottom: 7px;

    @media only screen and (max-width: $max-1800) {
        margin-left: 25px;
    }

    @media only screen and (max-width: $max-1440) {
        // font-size: 14px;
        margin-left: 20px;
        // margin-bottom: 0;
    }

    @media only screen and (max-width: $max-1280) {
        @include font-medium();

        font-size: 26px;
        letter-spacing: 0.1em;
        margin-left: initial;
        margin-bottom: 10px;
    }

    @media only screen and (max-width: $max-768) {
        font-size: 26px;
        margin-top: 0;
    }

    .bar {
        // margin-top: -6px;
        border-top: 1px solid $www-primary;
        margin: -7px 0 6px -1px;
        width: 0;

        @media only screen and (max-width: $max-1280) {
            display: none;
            margin-top: 10px;
            margin-bottom: -3px;
        }
    }

    &.withChildren {
        cursor: default;
    }

    .text {
        display: flex;
        align-items: center;

        .title {
            // To make it look right in IE, otherwise 100% would work
            flex: 0 1 auto;
            cursor: pointer;

            @media only screen and (max-width: $max-1280) {
                flex: 0 1 100%;
            }
        }

        .logo {
            font-size: 12px;

            @media only screen and (max-width: $max-1280) {
                flex: 0 0 22px;
                font-size: 20px;
                text-align: center;
            }

            .icon {
                // font-size: 14px;
                color: $color-font-gray;
                padding-left: 3px;

                &.external {
                    color: $www-primary;
                    margin-left: 5px;
                    transition: 0.2s ease-in-out;
                }

                &.mobileView {
                    display: none;

                    @media only screen and (max-width: $max-1280) {
                        display: inline-block;
                    }
                }
            }
        }

        .arrow {
            margin-left: 5px;
            margin-top: 5px;

            // @media only screen and (max-width: $max-1280) {
            //     display: none;
            // }
        }
        // }
    }

    .dropdown {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        display: flex;
        flex-direction: column;
        background: $www-night-black;
        min-width: 300px;
        padding: 15px 20px;
        top: 40px;
        width: max-content;
        transform: translateY(10px);
        transition: 0.2s ease-in-out;

        &::after {
            content: '';
            position: absolute;
            top: -20px;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        a {
            margin-top: 15px;
            transition: 0.2s ease-in-out;
            color: #fff;

            &.selected {
                color: $www-theme-highlight;
            }

            &:nth-child(1) {
                margin-top: 0;
            }

            @media only screen and (min-width: $min-1280) {
                &:hover {
                    color: $www-theme-highlight;
                }
            }
        }

        @media only screen and (max-width: $max-1280) {
            opacity: 1;
            visibility: visible;
            width: 100%;
            min-width: auto;
            position: unset;
            font-size: 20px;
        }
    }

    &.selected {
        .bar {
            width: 100%;
        }

        @media only screen and (max-width: $max-1280) {
            > a {
                color: $www-primary;
            }

            .bar {
                width: 0;
            }

            .title {
                margin-top: auto;
            }
        }
    }

    &:hover {
        .bar {
            width: 100%;
            transition: all ease-in-out 0.15s;

            @media only screen and (max-width: $max-1280) {
                width: 0;
            }

            @media only screen and (max-width: 421px) {
                width: 211px;
            }
        }

        .text .logo .icon.external {
            color: $www-primary;
            transition: 0.2s ease-in-out;
        }

        .dropdown {
            opacity: 1;
            visibility: visible;

            // Only translate in larger screens to not jump when hovering mobile nav
            @media only screen and (min-width: $min-1280) {
                transform: translateY(0);
            }
        }
    }

    // &.colorTheme {
    //     .bar {
    //         border-top: 1px solid $www-omega;
    //     }

    //     .text .logo .icon.external {
    //         margin-left: 5px;
    //         color: $www-omega;
    //         opacity: 1;
    //     }

    //     .text:hover {
    //         .logo .icon.external {
    //             opacity: 1;
    //         }
    //     }
    // }

    // &.fpTheme {
    //     .bar {
    //         border-top: 1px solid $brand-primary;
    //     }

    //     .text .logo .icon.external {
    //         margin-left: 5px;
    //         color: $brand-primary;
    //         opacity: 1;
    //     }

    //     .text .arrow {
    //         color: $brand-primary;
    //     }

    //     .text:hover {
    //         .logo .icon.external {
    //             opacity: 1;
    //         }
    //     }

    //     .dropdown {
    //         a {
    //             &.selected {
    //                 color: $brand-primary;
    //             }

    //             @media only screen and (min-width: $min-1280) {
    //                 &:hover {
    //                     color: $brand-primary;
    //                 }
    //             }
    //         }
    //     }
    // }

    &.mobile {
        // height: 45px;
        // height: 50px;
        overflow: hidden;
        letter-spacing: 0.1em;
        transition: transform 0.3s cubic-bezier(0.41, 0.28, 0.66, 0.97),
            opacity 1s ease, height 0.3s ease;

        > a {
            text-transform: uppercase;
            padding: 15px 0;
        }

        // @for $i from 1 to 7 {
        //     &:nth-child(#{$i + 1}) {
        //         transition-delay: $i * 0.1s + 0.1s;
        //     }
        // }

        .dropdownMobile {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            margin: 10px 0;
            opacity: 0;
            transition: opacity 0.3s ease 0.1s;
            line-height: 1.2;
            font-size: 22px;

            > a {
                padding: 10px 0;
                font-size: 18px;
            }
        }

        .text .arrow {
            transition: $transition;
            color: $color-font-gray;
        }

        &.active {
            .dropdownMobile {
                opacity: 1;
            }

            .arrow {
                transform: rotate(180deg);
            }
        }

        @include viewport(xsMax) {
            font-size: 24px;

            .dropdownMobile > a {
                font-size: 16px;
            }
        }
    }
}
