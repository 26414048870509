@import '~shared/styles/config';

.bottomPromo {
    padding: 0 0 50vw;
    margin-top: 40px;
    text-align: center;

    h2 {
        line-height: 1.2;
    }

    .btn {
        margin-top: 30px;
    }

    /******************************
        @media queries
    ****************************/

    @include viewport(tablet) {
        padding: 7vw 0 20vw;
        margin-top: 100px;
        min-height: 700px;

        .btn {
            margin-top: 50px;
        }
    }

    @include viewport(desktop) {
        padding: 5vw 0 26vw;
        margin-top: 5vw;
    }

    @include viewport(xlargeDesktopUp) {
        padding: 7vw 0 29vw;
    }
}
