@import '~shared/styles/config';

.anywhere {
    padding: 100px 0;

    @include viewport(mobile) {
        padding: 250px 0;
    }

    .sunset {
        padding: 20px;
        max-width: 850px;
        margin: 0 auto;
        background: #101010;
        text-align: center;
        border: 1px solid #d81f27;

        h4 {
            font-size: 20px;
            margin-top: 10px;
            font-weight: 600;
            text-transform: uppercase;
        }

        p {
            line-height: normal;
            font-size: 16px;
        }
        margin-bottom: 40px;
    }

    .loading {
        h2 {
            margin-bottom: 50px;
        }

        .dots {
            margin: 80px 0;
        }
    }

    /**
        Comment out display: grid; for now -- some issues with the SDK
    display: grid;
    justify-content: center;
    **/

    .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 90vh;
    }

    @include viewport(desktop) {
        min-height: 1080px;
    }
}
