@import '~shared/styles/config';

$selectedColor: rgba(48, 178, 230, 0.5);
$successColor: rgb(0, 128, 0);
$failedColor: rgb(128, 0, 0);
$backgroundColor: rgba(0, 0, 0, 0.5);

$titleIdlePadding: 0.25rem 0.5rem;
$titleActivePadding: 0.25rem 1rem 0.25rem 1rem;
$titleIdleMargin: 0 1rem 0.5rem 1rem;
$titleActiveMargin: 0 0.5rem 0.5rem 0.5rem;

.characterPanel {
    width: 100%;
    user-select: none;

    > h2 {
        font-size: 125%;
        text-transform: none;
        display: flex;
        text-align: left;
        padding: $titleIdlePadding;
        margin: $titleIdleMargin;
        background-color: $backgroundColor;
        transition: all 0.2s ease-in-out;
    }

    .characterImageOuter {
        padding: 0.5rem;
        margin: 0.5rem;
        position: relative;
        border: 2px solid rgba(255, 255, 255, 0);
        transition: all 0.2s ease-in-out 0.1s;

        .characterImageInner {
            padding: 0.5rem;
            background-color: $backgroundColor;
        }
    }

    .characterImage {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        -webkit-filter: grayscale(100%);
        -moz-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.8;
        transition: all 0.2s ease-in-out;

        .aspectRatio {
            position: relative;
            width: 100%;
            &::before {
                content: '';
                float: left;
                padding-top: 100%;
            }
            &::after {
                content: '';
                display: block;
                clear: both;
            }
        }

        > img {
            position: absolute;
            width: 20%;
            height: 20%;

            &.corpLogo {
                bottom: 0;
                left: 0;
            }
            &.allianceLogo {
                bottom: 0;
                right: 0;
            }
        }
    }

    &.selectedCharacter {
        > h2 {
            margin: $titleActiveMargin;
            padding: $titleActivePadding;
        }

        .characterImageOuter {
            border-color: $selectedColor;

            .characterImage {
                opacity: 1;
                -webkit-filter: grayscale(0%);
                -moz-filter: grayscale(0%);
                filter: grayscale(0%);
            }
        }
    }
    &.successfulLink {
        > h2 {
            margin: $titleActiveMargin;
            padding: $titleActivePadding;
        }

        .characterImageOuter {
            border-width: 4px;
            border-color: $successColor;

            .characterImage {
                opacity: 1;
                -webkit-filter: grayscale(0%);
                -moz-filter: grayscale(0%);
                filter: grayscale(0%);
            }
        }
    }
    &.failedLink {
        > h2 {
            margin: $titleActiveMargin;
            padding: $titleActivePadding;
        }

        .characterImageOuter {
            border-width: 4px;
            border-color: $failedColor;
        }
    }

    &:hover {
        > h2 {
            margin: $titleActiveMargin;
            padding: $titleActivePadding;
        }
        .characterImageOuter {
            border-color: rgba(255, 255, 255, 0.5);

            .characterImage {
                opacity: 1;
                -webkit-filter: grayscale(0%);
                -moz-filter: grayscale(0%);
                filter: grayscale(0%);
            }
        }
    }
}

@media only screen and (max-width: $max-960) {
    .characterPanel {
        > h2 {
            text-align: center;
            justify-content: center;
        }
    }
}
