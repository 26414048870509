@import '~shared/styles/config';

.alreadySection {
    position: relative;
    padding-bottom: 25px;
    margin-top: 15px;
    padding-top: 10px;

    &::before {
        content: '';
        display: block;
        height: 1px;
        background-color: #474746;
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        z-index: 1;
        width: 150%;
        margin-left: -20%;
    }
}

.login {
    text-align: left;
    margin: 20px 0 15px 0;
    font-size: 16px;
    letter-spacing: 0.06em;
    font-weight: $font-regular;
    color: #fff;

    a {
        color: $www-primary;
    }
}

.btn {
    width: 100%;
}
