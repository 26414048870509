@import '~shared/styles/config';

.scroll {
    display: none; // show on desktop only
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    text-align: center;
    opacity: 1;
    transition: opacity 0.5s;
    transition-delay: 0.5s;
    z-index: 1;


    &.hidden {
        opacity: 0;
    }
}

.arrow {
    position: relative;
    width: 42px;
    height: 15px;
    top: -25px;
    animation: arrow 2.25s ease-in-out infinite;

    &:nth-child(2) {
        animation-delay: 0.35s;
    }

    &::before,
    &::after {
        content: '';
        width: 16px;
        height: 2px;
        background-color: #fff;
        border-radius: 2px;
        display: inline-block;
    }

    &::before {
        transform: rotate(45deg) translateX(25%);
    }

    &::after {
        transform: rotate(-45deg) translateX(-25%);
    }
}

@keyframes arrow {
    from {
        opacity: 0;
    }

    to {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

/******************************
    @media queries
****************************/

@include viewport(desktop) {
    .scroll {
        display: block;
    }
}
