@import '~shared/styles/config';

.embedInline {
    .tooltip {
        width: 100%;
        max-width: 320px;
        padding: 0;
        pointer-events: auto !important;

        &:hover {
            opacity: 1 !important;
        }

        span {
            position: relative;
            display: grid;
            grid-gap: 10px;
            padding: 15px;

            &::after {
                content: '';
                position: absolute;
                top: -5px;
                left: 20px;
                border-bottom: 5px solid $www-space-gray;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                height: 0;
                width: 83px;
                z-index: 1;
            }
        }

        a {
            position: relative;
            padding-bottom: 15px;
        }

        img {
            position: relative;
            width: 100%;
            height: 180px;
            object-fit: cover;
        }

        h3,
        p {
            margin: 0;
            color: #fff;
            line-height: 1.2;
        }

        h3 {
            font-size: 22px;
        }

        p {
            font-size: 18px;
        }
    }

    :global {
        .__react_component_tooltip {
            opacity: 1 !important;
        }
    }
}
